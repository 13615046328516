import { Observable } from "rxjs";

import { BaseFilter } from "./bases/base-filter";
import { BaseModel, License } from "./bases/base-model";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";
import { JuridicTypeEnum } from "../utils/enum/juridic-type.enum";
export interface JuridicType extends BaseModel {
  id?: string;
  name?: string;
  code?: JuridicTypeEnum;
}

export interface JuridicTypeFilter extends JuridicType, BaseFilter {}

export abstract class JuridicTypeData {
  abstract list(
    filter: JuridicTypeFilter
  ): Observable<BaseResponse<JuridicType[], BaseResponsePagination>>;
}
