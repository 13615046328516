import { Observable } from "rxjs";

import { BaseFilter } from "./bases/base-filter";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";
import { JuridicRequestDto, JuridicResponseDto } from "./dto/juridic.dto";
import { JuridicTypeEnum } from "../utils/enum/juridic-type.enum";

export interface JuridicFilter extends BaseFilter {
  comercialName?: string;
  companyName?: string;
  cnpj?: string;
  // juridicTypeIds?: string[];
  juridicTypeIdsIn?: string[];
  juridicTypeIdsAnd?: string[];
}

export abstract class JuridicData {
  abstract list(
    filter: JuridicFilter
  ): Observable<BaseResponse<JuridicResponseDto[], BaseResponsePagination>>;
  abstract getById(
    id: string
  ): Observable<BaseResponse<JuridicResponseDto, BaseResponsePagination>>;
  abstract update(id: string, request: JuridicRequestDto);
  abstract delete(id);
  abstract insert(request: JuridicRequestDto);
}
