import { Observable } from "rxjs";
import { BaseResponse } from "./bases/base-response";
import { Bank } from "./bank";
import { User } from "./user";

export interface Account<T> {
  id?:number;
  agency?:string;
  bank_account?:string;
  project_number?:string;
  project_name?:string;
  project_agreement?:string;
  bank?:T;
  users?:any[];
}

export interface AccountFilter<T> {
  id?:number;
  agency?:string;
  bank_account?:string;
  bank?:T;
  project_number?:string;
  user?:User;
}

export abstract class AccountData {
  abstract list(filter:Account<number>, pagination:any): Observable<BaseResponse<Account<Bank>[],any>>
  abstract insert(account:Account<number>)
  abstract update(account:Account<number>)
  abstract get(id): Observable<BaseResponse<Account<Bank>,any>>
  abstract delete(id)
  abstract listByUser(userId:string, pagination?:any, filter?:Account<Bank>): Observable<BaseResponse<Account<Bank>[],any>>
}
