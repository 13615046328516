import { dateToFormatFromString } from "src/app/@core/utils/common/datetime-utils";
import { environment } from "../../../../../environments/environment";
import { VersionHistoryActionComponent } from "./version-history-action/version-history-action.component";

export const VersionHistoryListTableSettings = ({
  onDisplay,
}: {
  onDisplay: (versionId) => void;
}) => {
  return {
    pager: {
      perPage: environment.pages.logHistory.recordsPerPage,
      display: true,
    },
    mode: "external",
    hideSubHeader: true,
    selectedRowIndex: -1,
    actions: {
      columnTitle: "",
      add: false,
      edit: false,
      delete: false,
      position: "right",
    },
    columns: {
      logId: {
        title: "Id",
        hide: true,
      },
      timestamp: {
        title: "Data da Alteração",
        valuePrepareFunction: (cell) => {
          return dateToFormatFromString(cell, "DD/MM/YYYY");
        }
      },
      version: {
        title: "Versão",
      },
      user: {
        title: "Alterado por",
      },

      action: {
        title: "",
        type: "custom",
        renderComponent: VersionHistoryActionComponent,
        valuePrepareFunction: (cell, row, rowIndex) => {
          row.qty = rowIndex.dataSet.data.length;
          return row;
        },
        onComponentInitFunction(instance) {
          instance.onDisplay.subscribe((row) => {
            if (onDisplay) onDisplay(row);
          });
        },
      },
    },
  };
};
