import { Observable } from "rxjs";

import { BaseFilter } from "./bases/base-filter";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";
import { DocumentRequestDto, DocumentResponseDto } from "./dto/document.dto";

export interface JuridicDocumentFilter extends BaseFilter {
  id?: string;
}

export abstract class JuridicDocumentData {
  abstract list(
    juridicId: string,
    filter: JuridicDocumentFilter
  ): Observable<BaseResponse<DocumentResponseDto[], BaseResponsePagination>>;
  abstract getById(
    id: string
  ): Observable<BaseResponse<DocumentResponseDto, BaseResponsePagination>>;
  abstract insert(juridicId: string, formData: FormData): Observable<any>;
  abstract delete(id: string): Observable<any>;
}
