import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import {
  BaseResponse,
  BaseResponsePagination,
} from "../data/bases/base-response";
import {
  AgreementType,
  AgreementTypeData,
  AgreementTypeFilter,
} from "../data/agreement-type";
import { setBaseFilter } from "../utils/http/base-http-filter";

@Injectable()
export class AgreementTypeService extends AgreementTypeData {
  private baseUrl = `${environment.services.project.baseUrl}/api/v1/agreement_type`;

  constructor(private http: HttpClient) {
    super();
  }

  list(
    filter: AgreementTypeFilter
  ): Observable<BaseResponse<AgreementType[], BaseResponsePagination>> {
    var params = setBaseFilter(filter);

    return this.http.get<BaseResponse<AgreementType[], BaseResponsePagination>>(
      `${this.baseUrl}`,
      { params }
    );
  }
}
