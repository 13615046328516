import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: "ngx-version-history-action",
  templateUrl: "./version-history-action.component.html",
  styleUrls: ["./version-history-action.component.scss"],
})
export class VersionHistoryActionComponent implements OnInit {
  constructor() {}

  @Input() rowData: any;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onDisplay = new EventEmitter<any>();

  shouldDisplayButton = false

  ngOnInit(): void {
   this.shouldDisplayButton = this.rowData.version !== this.rowData.qty -1
  }

  handleDisplay() {
    this.onDisplay.emit(this.rowData);
  }
 
}
