import { AgreementResponseDto } from "src/app/@core/data/dto/agreement.dto";

export class SaveVersion {
  static readonly type = "[Agreement] - SaveVersion";
  constructor(public version: number) {}
}

export class SaveAgreement {
  static readonly type = "[Agreement] - SaveAgreement";
  constructor(public agreementResponseDto: AgreementResponseDto) {}
}
