import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import {
  BaseResponse,
  BaseResponsePagination,
} from "../data/bases/base-response";
import {
  InvestimentType,
  InvestimentTypeData,
  InvestimentTypeFilter,
} from "../data/investiment-type";
import { setBaseFilter } from "../utils/http/base-http-filter";

@Injectable()
export class InvestimentTypeService extends InvestimentTypeData {
  private baseUrl = `${environment.services.accessControl.baseUrl}/api/v1/agreement-type`;

  constructor(private http: HttpClient) {
    super();
  }

  list(
    filter: InvestimentTypeFilter
  ): Observable<BaseResponse<InvestimentType[], BaseResponsePagination>> {
    var params = setBaseFilter(filter);

    return of({
      result: [
        {
          id: "guid",
          name: "Tipo de Investimento 1",
        },
        {
          id: "guid2",
          name: "Tipo de Investimento 2",
        },
        {
          id: "guid3",
          name: "Tipo de Investimento 3",
        },
      ],
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
    });

    return this.http.get<
      BaseResponse<InvestimentType[], BaseResponsePagination>
    >(`${this.baseUrl}`, { params });
  }
}
