<nb-card>
  <nb-card-body>
    <h5>Histórico de versões - {{moduleName}}</h5>
    <div class="row">
      <div class="col-md-6">
        <h6>Versão Atual</h6>
        <div class="version-info">
          <span>Data da alteração: {{currentVersionSummary.timestamp | date : 'dd/MM/yyyy'}}</span>
          <span>Alterado por: {{currentVersionSummary.user}} </span>
        </div>
        <p *ngFor="let version of currentVersionDataArray">
          <b>{{ version.label }}:&nbsp;</b>
          <ng-container *ngTemplateOutlet="displayValue; context: { value: version.value }"></ng-container>
        </p>
      </div>
      <div class="col-md-6 border-left">
        <h6>Versão {{previousVersionSummary.version}}</h6>
        <div class="version-info">
          <span>Data da alteração: {{previousVersionSummary.timestamp | date : 'dd/MM/yyyy'}}</span>
          <span>Alterado por: {{previousVersionSummary.user}} </span>
        </div>
        <p *ngFor="let version of previousVersionDataArray">
          <b>{{ version.label }}:&nbsp;</b>
          <ng-container *ngTemplateOutlet="displayValue; context: { value: version.value }"></ng-container>
        </p>
      </div>
    </div>

  </nb-card-body>
</nb-card>

<ng-template #displayValue let-value="value">
  <ng-container *ngIf="isArray(value); else textValue">
    <div class="list inner" *ngFor="let item of value">
      <div *ngFor="let inner of item">
        <b>{{ inner.label }}: &nbsp;</b>
        <ng-container *ngTemplateOutlet="displayValue; context: { value: inner.value }"></ng-container>
      </div>

    </div>
  </ng-container>
  <ng-template #textValue>
    <span>{{ value }}</span>
  </ng-template>
</ng-template>