import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Log, LogSummary } from "../data/dto/log.dto";

@Injectable()
export class FinancierLogService {
  
  private projectBaseUrl = environment.services.project.baseUrl;

  constructor(private http: HttpClient) {}

  getFinancierPaymentLogList(financierId: string): Observable<LogSummary[]> {
    return this.http.get<LogSummary[]>(
      `${this.projectBaseUrl}/api/v1/financier-payment/log-summary/${financierId}`
    );
  }

  getFinancierPaymentLogById(financierId: string, id: string): Observable<Log> {
    return this.http.get<Log>(
      `${this.projectBaseUrl}/api/v1/financier-payment/log/${financierId}`,
      { params: { logId: id } }
    );
  }

  getFinancierScheduleLogList(financierId: string): Observable<LogSummary[]> {
    return this.http.get<LogSummary[]>(
      `${this.projectBaseUrl}/api/v1/financier-schedule/log-summary/${financierId}`
    );
  }

  getFinancierScheduleLogById(
    financierId: string,
    id: string
  ): Observable<Log> {
    return this.http.get<Log>(
      `${this.projectBaseUrl}/api/v1/financier-schedule/log/${financierId}`,
      { params: { logId: id } }
    );
  }
}
