import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { VersionHistoryListTableSettings } from "./version-history.tablesettings";
import { Observable, combineLatest } from "rxjs";
import { NbDialogRef, NbDialogService } from "@nebular/theme";
import { HistoryDisplayComponent } from "./history-display/history-display.component";
import { Log, LogSummary } from "src/app/@core/data/dto/log.dto";

@Component({
  selector: "ngx-version-history",
  templateUrl: "./version-history.component.html",
  styleUrls: ["./version-history.component.scss"],
})
export class VersionHistoryComponent implements OnInit {
  constructor(
    private cd: ChangeDetectorRef,
    protected ref: NbDialogRef<VersionHistoryComponent>,
    private dialogService: NbDialogService
  ) {}


  /**
   * Define the title that will be shown with the dialog title.
   * Example: "Pagamento". It will be show as "Histórico de Versões - Pagamento"
   */
  @Input() moduleName: string;

  /**
   * The function to be called to getVersionList.
   */
  @Input() getVersionList: () => Observable<LogSummary[]>;

  /**
   * The function to be called to getVersionData.
   */
  @Input() getVersionData: (versionId: string) => Observable<Log>;

  /**
   * Event emitter to handle the closing of the dialog.
   */
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onClose = new EventEmitter<void>();

  settings: any = null;
  source: LogSummary[] = [];

  ngOnInit(): void {
    this.getVersionList().subscribe((data) => {
      this.loadSettings();
      this.source = data.sort(
        (a, b) =>
          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
      );
    });
  }

  private loadSettings() {
    this.settings = VersionHistoryListTableSettings({
      onDisplay: (row) => {
        this.handleDisplay(row.logId);
      },
    });
    this.cd.detectChanges();
  }

  private handleDisplay(versionId: string) {
    const currentVersion = this.source[0]
    combineLatest([
      this.getVersionData(currentVersion.logId),
      this.getVersionData(versionId),
    ]).subscribe(([currentVersionData, previousVersionData]) => {
      this.dialogService
        .open(HistoryDisplayComponent, {
          dialogClass: "version-history-compare-dialog",
          context: {
            currentVersionData,
            previousVersionData,
            moduleName: this.moduleName,
            currentVersionSummary: this.source[0],
            previousVersionSummary: this.source.find(item => item.logId === versionId),
          },
        })
        .onClose.subscribe((data) => {});
    });
  }
}

