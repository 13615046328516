import { Observable } from "rxjs";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";
import { UserGroup } from "./user-group";
import { BaseFilter } from "./bases/base-filter";
import { BaseModel, License } from "./bases/base-model";
import { Person } from "./person";

export interface User extends BaseModel {
  id?:string;
  email?:string;
  person?:Person;
  userGroups?:UserGroup[];
  license?:License;
  master?:boolean;
}

export interface UserAccControl {
  id?: string;
  email?: string;
  isActive?: boolean;
  license?: Licence;
  person?: Person;
}

export interface Licence{
  modules?: string[];
  funcionalities?: string[];
}

export enum ProfileEnum {
  Administrador = 'ADM',
  DebitoCredito = 'DEC',
  Credito = 'CRE',
}

export interface UserFilter extends User,BaseFilter {
}

export abstract class UserData {
  abstract insert(user:User)
  abstract update(user:User)
  abstract list(filter:UserFilter, pagination:any): Observable<BaseResponse<User[],BaseResponsePagination>>
  abstract delete(id)
}

