import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import {
  BaseResponse,
  BaseResponsePagination,
} from "../data/bases/base-response";
import {
  RestrictionRequestDto,
  RestrictionResponseDto,
} from "../data/dto/restriction.dto";
import {
  JuridicRestrictionData,
  JuridicRestrictionFilter,
} from "../data/juridic-restriction";
import { setBaseFilter } from "../utils/http/base-http-filter";

@Injectable()
export class JuridicRestrictionService extends JuridicRestrictionData {
  private baseUrl = `${environment.services.accessControl.baseUrl}/api/v1/juridic_restriction`;

  constructor(private http: HttpClient) {
    super();
  }

  getById(
    id: string
  ): Observable<BaseResponse<RestrictionResponseDto, BaseResponsePagination>> {
    return this.http.get<
      BaseResponse<RestrictionResponseDto, BaseResponsePagination>
    >(`${this.baseUrl}/${id}`);
  }

  list(
    juridicId: string,
    filter: JuridicRestrictionFilter
  ): Observable<
    BaseResponse<RestrictionResponseDto[], BaseResponsePagination>
  > {
    var params = setBaseFilter(filter);
    if (filter.id) params = params.set("id", filter.id);

    return this.http.get<
      BaseResponse<RestrictionResponseDto[], BaseResponsePagination>
    >(`${this.baseUrl}/by-juridic/${juridicId}`, { params });
  }

  insert(juridicId: string, request: RestrictionRequestDto): Observable<any> {
    return this.http.post(`${this.baseUrl}/${juridicId}`, {
      ...request,
    });
  }
  delete(id: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }
}
