import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { CountPages } from "../../../@core/utils/common/count-pages";

@Component({
  selector: "ngx-pager",
  templateUrl: "./pager.component.html",
  styleUrls: ["./pager.component.scss"],
})
export class PagerComponent implements OnInit, OnChanges {
  @Input() currentPage: number;
  @Output() currentPageChange: EventEmitter<number> =
    new EventEmitter<number>();
  @Input() perPage: number = 10;
  @Input() total: number;
  @Input() size: string = "normal";
  countPages: number;
  pagerLimit: number;
  lot: number = 1;
  lotSize: number;
  lotEnd: number;
  indexPage: number = 1;
  pages = new Array();

  constructor() {}

  ngOnInit(): void {
    this.currentPage = 1;
    this.pagerLimit = this.size == "normal" ? 10 : 5;
    this.lotEnd =
      this.indexPage + this.pagerLimit - 1 > this.countPages
        ? this.countPages
        : this.indexPage + this.pagerLimit - 1;
    // this.print()
    this.currentPageChange.emit(this.currentPage);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes?.total != null && this.total != 0) {
    this.countPages = CountPages(this.total, this.perPage);
    this.pages = [];
    this.lotSize = Math.trunc(this.countPages / this.pagerLimit) + 1;
    this.lotEnd =
      this.indexPage + this.pagerLimit - 1 > this.countPages
        ? this.countPages
        : this.indexPage + this.pagerLimit - 1;

    for (let index = this.indexPage; index <= this.lotEnd; index++) {
      if (!this.pages.find((p) => p == index)) {
        this.pages.push(index);
      }
    }
    // }
  }

  onPageClick(page) {
    this.currentPage = page;

    if (this.currentPage > this.lotEnd) {
      this.onLot(1);
    } else {
      if (this.currentPage < this.indexPage) {
        this.onLot(-1, true);
      } else {
        this.currentPageChange.emit(this.currentPage);
      }
    }

    // this.print()
  }

  onLot(page: number, previousLastPage?: boolean) {
    this.lot += page;
    this.indexPage = (this.lot - 1) * this.pagerLimit + 1;
    this.lotEnd =
      this.indexPage + this.pagerLimit - 1 > this.countPages
        ? this.countPages
        : this.indexPage + this.pagerLimit - 1;
    this.currentPage = previousLastPage ? this.currentPage : this.indexPage;
    this.currentPageChange.emit(this.currentPage);
  }
}
