import { Injectable } from "@angular/core";
import {
  Action,
  createSelector,
  Selector,
  State,
  StateContext,
} from "@ngxs/store";

import { ScreenTables } from "../../utils/enum/screen-tables.enum";
import { SaveAgreement, SaveVersion } from "./agreement.action";
import { AGREEMENT_STATE_NAME, initialState } from "./agreement.constant";
import { AgreementStateModel } from "./agreement.interface";

@State<AgreementStateModel>({
  name: AGREEMENT_STATE_NAME,
  defaults: initialState,
})
@Injectable()
export class AgreementState {
  public static readonly TOKEN = AGREEMENT_STATE_NAME;

  @Action(SaveVersion)
  saveVersion(
    ctx: StateContext<AgreementStateModel>,
    { version }: SaveVersion
  ) {
    ctx.patchState({ version });
  }

  @Action(SaveAgreement)
  saveAgreement(
    ctx: StateContext<AgreementStateModel>,
    {
      agreementResponseDto: { version, agreementIdentifier, status, startDate },
    }: SaveAgreement
  ) {
    ctx.patchState({ version, agreementIdentifier, status, startDate });
  }
  @Selector([AgreementState])
  static state(state: AgreementStateModel): AgreementStateModel {
    return state;
  }

  static version(state: AgreementStateModel): number {
    return state.version;
  }
}
