import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseResponse, BaseResponsePagination } from '../data/bases/base-response';
import { PartnerRequestDto, PartnerResponseDto } from '../data/dto/partner.dto';
import { JuridicPartnerData, JuridicPartnerFilter } from '../data/juridic-partner';
import { setBaseFilter } from '../utils/http/base-http-filter';

@Injectable()
export class JuridicPartnerService extends JuridicPartnerData {
  private baseUrl = `${environment.services.accessControl.baseUrl}/api/v1/partner`;

  constructor(private http: HttpClient) {
    super();
  }

  getById(
    id: string
  ): Observable<BaseResponse<PartnerResponseDto, BaseResponsePagination>> {
    return this.http.get<
      BaseResponse<PartnerResponseDto, BaseResponsePagination>
    >(`${this.baseUrl}/${id}`);
  }

  list(
    juridicId: string,
    filter: JuridicPartnerFilter
  ): Observable<BaseResponse<PartnerResponseDto[], BaseResponsePagination>> {
    var params = setBaseFilter(filter);
    if (filter.id) params = params.set("id", filter.id);

    return this.http.get<
      BaseResponse<PartnerResponseDto[], BaseResponsePagination>
    >(`${this.baseUrl}/by-juridic/${juridicId}`, { params });
  }
  update(id: string, request: PartnerRequestDto): Observable<any> {
    return this.http.put(`${this.baseUrl}/${id}/`, request);
  }
  insert(juridicId: string, request: PartnerRequestDto): Observable<any> {
    return this.http.post(`${this.baseUrl}/${juridicId}`, {
      ...request,
      id: null,
    });
  }
  delete(id: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }
}
