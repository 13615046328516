import { Observable } from 'rxjs';

import { BaseFilter } from './bases/base-filter';
import { BaseModel } from './bases/base-model';
import { BaseResponse, BaseResponsePagination } from './bases/base-response';

export interface InvestimentType extends BaseModel {
  id?: string;
  name?: string;
}

export interface InvestimentTypeFilter extends InvestimentType, BaseFilter {}

export abstract class InvestimentTypeData {
  abstract list(
    filter: InvestimentTypeFilter
  ): Observable<BaseResponse<InvestimentType[], BaseResponsePagination>>;
}
