<label id="{{ label }}" class="label">{{ label }}</label>
<input
  nbInput
  fullWidth
  [formControlName]="formControlName"
  placeholder="{{ placeholder }}"
  [status]=""
/>
<!-- <p
    class="caption status-danger"
    *ngFor="
      let error of formService.getErrosStringList('mecMctiRegister', form)
    "
  >
    {{ error }}
  </p> -->
