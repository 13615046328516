import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import {
  BaseResponse,
  BaseResponsePagination,
} from "../data/bases/base-response";
import {
  JuridicItemListResponseDto,
  JuridicRequestDto,
  JuridicResponseDto,
} from "../data/dto/juridic.dto";
import { JuridicData, JuridicFilter } from "../data/juridic";
import { JuridicType } from "../data/juridic_type";
import { setBaseFilter } from "../utils/http/base-http-filter";

@Injectable()
export class JuridicService extends JuridicData {
  private baseUrl = `${environment.services.accessControl.baseUrl}/api/v1/juridic`;

  constructor(private http: HttpClient) {
    super();
  }

  list(
    filter: JuridicFilter
  ): Observable<
    BaseResponse<JuridicItemListResponseDto[], BaseResponsePagination>
  > {
    var params = setBaseFilter(filter);
    // if (filter.id) params = params.set("id", filter.id);
    return this.http.get<
      BaseResponse<JuridicItemListResponseDto[], BaseResponsePagination>
    >(`${this.baseUrl}`, { params });
  }
  getById(
    id: string
  ): Observable<BaseResponse<JuridicResponseDto, BaseResponsePagination>> {
    return this.http.get<
      BaseResponse<JuridicResponseDto, BaseResponsePagination>
    >(`${this.baseUrl}/${id}`);
  }
  update(id: string, request: JuridicRequestDto) {
    return this.http.put(`${this.baseUrl}/${id}/`, request);
  }
  delete(id: any) {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }
  insert(request: JuridicRequestDto) {
    return this.http.post<BaseResponse<string, BaseResponsePagination>>(
      `${this.baseUrl}`,
      {
        ...request,
        id: null,
      }
    );
  }

  // list(
  //   filter: JuridicFilter
  // ): Observable<BaseResponse<JuridicResponseDto[], BaseResponsePagination>> {
  //   var params = setBaseFilter(filter);

  //   if (filter.id) params = params.set("id", filter.id);

  //   if (filter.name) params = params.set("name", filter.name);

  //   if (filter.isActive) params = params.set("isActive", filter.isActive);

  //   return this.http.get<
  //     BaseResponse<JuridicResponseDto[], BaseResponsePagination>
  //   >(`${this.baseUrl}/api/v1/juridic_type/`, { params });
  // }
}
