export interface DocumentResponseDto {
  id?: string;
  documentId: string;
  originalName: string;
  fileName?: string;
  url?: string;
  dueDate?: string;
  status?: string;
}

export interface DocumentRequestDto {
  id?: string;
  documentId: string;
  originalName: string;
  dueDate?: string;
  status?: string;
  base64?: string;
}
