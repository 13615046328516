import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import {
  BaseResponse,
  BaseResponsePagination,
} from "../data/bases/base-response";
import { Modality, ModalityData, ModalityFilter } from "../data/modality";
import { setBaseFilter } from "../utils/http/base-http-filter";

@Injectable()
export class ModalityService extends ModalityData {
  private baseUrl = `${environment.services.project.baseUrl}/api/v1/modality`;

  constructor(private http: HttpClient) {
    super();
  }

  list(
    filter: ModalityFilter
  ): Observable<BaseResponse<Modality[], BaseResponsePagination>> {
    var params = setBaseFilter(filter);

    return this.http.get<BaseResponse<Modality[], BaseResponsePagination>>(
      `${this.baseUrl}`,
      { params }
    );
  }
}
