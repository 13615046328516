<nb-card
  [nbSpinner]="isLoading"
  nbSpinnerSize="small"
  nbSpinnerStatus="primary"
>
  <nb-card-header>
    <div class="row show-grid flex-nowrap" style="white-space: nowrap">
      <div class="col-md-8">
        <div>Selecionar Pessoa Jurídica</div>
      </div>
      <div class="col-md-4 action-menu">
        <nb-actions size="small">
          <nb-action>
            <div
              style="
                display: flex;
                flex-direction: column;
                width: 25px;
                align-self: center;
              "
            >
              <div
                (click)="onToggleFilter()"
                [ngClass]="showFilter ? 'nofilter' : 'filter'"
              ></div>
            </div>
          </nb-action>
        </nb-actions>
      </div>
    </div>
  </nb-card-header>

  <nb-card-body>
    <div class="row">
      <nb-card *ngIf="showFilter" class="col-md-12">
        <form [formGroup]="form">
          <nb-card-body>
            <div>
              <div class="form-group col-sd-6">
                <label for="inputFirstName" class="label">CNPJ</label>
                <input
                  [disabled]="disabledFields.cnpj"
                  nbInput
                  fullWidth
                  [formControlName]="'cnpj'"
                  placeholder="CNPJ"
                  mask="00.000.000/0000-00"
                />
              </div>
              <div class="form-group col-sd-6">
                <label for="inputFirstName" class="label">Razão Social</label>
                <input
                  [disabled]="disabledFields.companyName"
                  nbInput
                  fullWidth
                  [formControlName]="'companyName'"
                  placeholder="Razão Social"
                />
              </div>
              <div class="form-group col-sd-6">
                <label for="inputFirstName" class="label">Nome Fantasia</label>
                <input
                  [disabled]="disabledFields.comercialName"
                  nbInput
                  fullWidth
                  [formControlName]="'comercialName'"
                  placeholder="Nome Fantasia"
                />
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-3" *ngFor="let item of juridicTypeOptions">
                <nb-checkbox
                  [disabled]="disabledFields.juridicTypes"
                  (checkedChange)="onTypeToggle(item.id, $event)"
                  [checked]="isTypeChecked(item.id)"
                  >{{ item.name }}</nb-checkbox
                >
              </div>
            </div>
            <div
              class="row flex-nowrap"
              style="display: flex; justify-content: center"
            >
              <button
                type="submit"
                nbButton
                status="primary"
                class="button"
                (click)="onFilter()"
              >
                Filtrar
              </button>
              <button type="reset" nbButton class="button" (click)="onClear()">
                Limpar
              </button>
            </div>
          </nb-card-body>
        </form>
      </nb-card>
    </div>

    <div class="row">
      <div class="col-md-12">
        <ng2-smart-table
          [settings]="settings"
          [source]="source"
          (userRowSelect)="onClickRow($event)"
        >
        </ng2-smart-table>

        <ngx-pager
          [(currentPage)]="pagination.currentPage"
          (currentPageChange)="onCurrentPageChange($event)"
          [perPage]="pagination.pageSize"
          [total]="total"
        ></ngx-pager>
      </div>
    </div>
  </nb-card-body>
</nb-card>
