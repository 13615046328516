import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'ngx-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {

  @Input() title: string;
  @Input() description: string;
  @Input() records: string[] = [];
  @Input() component?: any;

  constructor(protected ref: NbDialogRef<DialogComponent>) {

  }

  submit(value: boolean) {
    console.log(this.component)
    this.ref.close(value)
  }

}
