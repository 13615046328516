type ErrorTuple = [string, string];

export const errorEnum: ErrorTuple[] = [
  ["118", "CPF/CNPJ já cadastrado."],
  ["1501", "Razão social é obrigatório."],
  ["1601", "Sócio já cadastrado."],
  ["2000", "Já existe uma apoiada na avença."],
  ["2007", "Participante já cadastrado."],
  ["4002", "O Juridic deve ser um participante da Avença."]
];
export function getValueByCode(
  code: string,
  defaultMessage = "Value not found"
): string {
  const item = errorEnum.find(([c]) => c === code);
  return item ? item[1] : defaultMessage;
}

export const throwCustomError = (
  error: any,
  defaultMessage = "Ocorreu um erro inesperado, contate o administrador."
): string => {
  try {
    if (
      !error ||
      !error.hasOwnProperty("errors") ||
      !Array.isArray(error.errors) ||
      error.errors.length <= 0
    ) {
      return defaultMessage;
    }

    return getValueByCode(error.errors[0].code, defaultMessage);
  } catch (error) {
    return defaultMessage;
  }
};
