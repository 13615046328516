import { Observable } from "rxjs";

import { BaseFilter } from "./bases/base-filter";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";
import {
  AgreementDocumentItemListResponseDto,
  AgreementDocumentRequestDto,
  AgreementDocumentResponseDto,
} from "./dto/agreement-document.dto";

export interface AgreementDocumentFilter extends BaseFilter {
  agreementId?: string;
}

export abstract class AgreementDocumentData {
  abstract list(
    filter: AgreementDocumentFilter
  ): Observable<
    BaseResponse<AgreementDocumentItemListResponseDto[], BaseResponsePagination>
  >;
  abstract getById(
    id: string
  ): Observable<
    BaseResponse<AgreementDocumentResponseDto, BaseResponsePagination>
  >;
  abstract delete(id);
  abstract insert(request: AgreementDocumentRequestDto);
}
