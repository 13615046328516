import { Observable } from "rxjs";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";
import { FinancierRequestDto, FinancierResponseDto } from "./dto/financier.dto";


export abstract class FinancierData {
    abstract get currentData()
    abstract insert(request: FinancierRequestDto)
    abstract get(): Observable<BaseResponse<FinancierResponseDto[], BaseResponsePagination>>;
    abstract getById(id: string): Observable<BaseResponse<FinancierResponseDto, BaseResponsePagination>>;
    abstract update(id: string, request: FinancierRequestDto);
    abstract updateFinancierId(id: string);
}