<form (ngSubmit)="confirmEmail(form)" #form="ngForm" aria-labelledby="title">
  <nb-card>
    <nb-card-header>Informe um e-mail</nb-card-header>
    <nb-card-body>
      <input nbInput fullWidth [(ngModel)]="email.address" #address="ngModel" name="address" id="ipt-emails"
        pattern=".+@.+\..+" placeholder="E-mail Alternativo" autofocus
        [status]="address.dirty ? (address.invalid  ? 'danger' : 'success') : 'basic'" [required]="true"
        [attr.aria-invalid]="address.invalid && address.touched ? true : null">
      <ng-container *ngIf="address.invalid && address.touched">
        <p class="caption status-danger" *ngIf="address.errors?.invalid">
          E-mail já cadastrado
        </p>
        <p class="caption status-danger" *ngIf="address.errors?.required">
          E-mail alternativo é obrigatório
        </p>
        <p class="caption status-danger" *ngIf="address.errors?.pattern">
          E-mail inválido
        </p>
      </ng-container>
    </nb-card-body>

    <nb-card-footer>
      <button type="submit" nbButton status="primary" class="button" id="btn-confirm" [disabled]="submitted || !form.valid"
        [class.btn-pulse]="submitted">Confirmar</button>
      <button type="reset" nbButton class="button" id="btn-close" (click)="close()">Fechar</button>
    </nb-card-footer>
  </nb-card>
</form>
