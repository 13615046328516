import { Observable } from "rxjs";

import { BaseFilter } from "./bases/base-filter";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";
import { AgreementRequestDto, AgreementResponseDto } from "./dto/agreement.dto";

export interface AgreementFilter extends BaseFilter {
  agreementIdentifier?: string;
  projectNumber?: string;
  projectName?: string;
  supported?: string;
  version?: string;
  status?: string;
  startDate?: string;
  endDate?: string;
  amount?: number;
  agreementTypeId?: string;
}

export abstract class AgreementData {
  abstract list(
    filter: AgreementFilter
  ): Observable<BaseResponse<any[], BaseResponsePagination>>;
  abstract getById(
    id: string
  ): Observable<BaseResponse<AgreementResponseDto, BaseResponsePagination>>;
  abstract update(id: string, request: AgreementRequestDto);
  abstract delete(id);
  abstract insert(request: AgreementRequestDto);
}
