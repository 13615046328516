import { Injectable } from "@angular/core";
import {
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrService,
} from "@nebular/theme";

import { environment } from "../../../../environments/environment";
import { LocalStorageData } from "../../data/local-storage";
import { ComponentStatus } from "../enum/component-status.enum";
import { ToastBodyEnum, ToastTitleEnum } from "../enum/message.enum";

const toastConfig = (
  position: NbGlobalPosition,
  type?: ComponentStatus,
  duration?: number
) => {
  return {
    status: type != null ? type : "basic",
    destroyByClick: true,
    duration: duration != null ? duration : 5000,
    hasIcon: true,
    position: position,
    preventDuplicates: false,
  };
};

@Injectable()
export class ToastService {
  constructor(
    private localStorage: LocalStorageData,
    private toastrService: NbToastrService
  ) {}

  show(title: string, body: string, type?: ComponentStatus, duration?: number) {
    var position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;

    switch (environment.toastPosition) {
      case "top-right":
        position = NbGlobalPhysicalPosition.TOP_RIGHT;
        break;
      case "top-left":
        position = NbGlobalPhysicalPosition.TOP_LEFT;
        break;
      case "bottom-right":
        position = NbGlobalPhysicalPosition.BOTTOM_RIGHT;
        break;
      case "bottom-left":
        position = NbGlobalPhysicalPosition.BOTTOM_LEFT;
        break;
      default:
        position = NbGlobalPhysicalPosition.TOP_RIGHT;
        break;
    }

    this.localStorage.set(
      "toastIndex",
      this.localStorage.get("toastIndex")
        ? this.localStorage.get("toastIndex") + 1
        : 1
    );

    this.toastrService.show(body, title, toastConfig(position, type, duration));
  }

  error(duration?: number) {
    this.show(
      ToastTitleEnum.Error,
      ToastBodyEnum.Error,
      ComponentStatus.Danger,
      duration
    );
  }

  errorCode(code: string, duration?: number) {
    this.show(
      ToastTitleEnum.Error,
      `[${code}] ${ToastBodyEnum.Error}`,
      ComponentStatus.Danger,
      duration
    );
  }

  errorMsg(message: string, duration?: number) {
    this.show(
      ToastTitleEnum.Error,
      `${message}`,
      ComponentStatus.Danger,
      duration
    );
  }

  errorTitleMsg(title: string, message: string, duration?: number) {
    this.show(`${title}`, `${message}`, ComponentStatus.Danger, duration);
  }

  successMsg(message: string, duration?: number) {
    this.show(
      ToastTitleEnum.Save,
      `${message}`,
      ComponentStatus.Success,
      duration
    );
  }
}
