import { Observable } from "rxjs";
import { BaseResponse } from "./bases/base-response";

export interface Menu {
    id?:string;
    funcionalityId?:string;
    title?:string;
    iconName?:string;
    iconPack?:string;
    link?:string;
    parentId?:string;
    order?:number;
    children?:Menu[];
    isPreProd:boolean;
    isProd:boolean;
}

export abstract class MenuData {
    abstract getByUser(): Observable<Menu[]>
  }