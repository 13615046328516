<div>
  <div *ngFor="let file of files; let $index = index">
    <div class="d-flex justify-content-between py-3 border-bottom px-7">
      <div class="d-flex align-items-center">
        <nb-icon
          [icon]="'file-outline'"
          pack="eva"
          (click)="deleteFile($index)"
          class="icon-file-item"
        ></nb-icon>
        <div class="d-flex flex-column">
          <span class="font-weight-bold">{{ file?.name }}</span>
          <span class="text-muted">{{ formatBytes(file?.size || 0) }}</span>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <nb-icon
          [icon]="'trash-2-outline'"
          pack="eva"
          (click)="deleteFile($index)"
          class="cursor"
        ></nb-icon>
        <!-- <app-progress [progress]="file?.progress"></app-progress> -->
      </div>
    </div>
  </div>
  <div
    *ngIf="showUpload"
    class=""
    ngxDragAndDrop
    (fileDropped)="onFileDropped($event)"
  >
    <label
      for="fileDropRef"
      class="half-border border-secondary dashed px-4 py-3 rounded cursor w-100"
    >
      <div class="d-flex justify-content-center">
        <nb-icon [icon]="'folder-add-outline'" pack="eva" class="icon-file">
        </nb-icon>
      </div>
      <br />
      Clique aqui e escolha seus documentos ou arraste e solte aqui
      <input
        type="file"
        #fileDropRef
        id="fileDropRef"
        class="d-none"
        (change)="fileBrowseHandler($event)"
        [multiple]="multiple"
      />
      <p class="mt-2 text-xs font-weight-medium text-center text-muted">
        {{ getDescription() }}
        <br />
        <span class="caption status-danger ng-star-inserted">{{
          message || errorMessage
        }}</span>
        <span
          class="caption status-danger ng-star-inserted"
          *ngFor="let errorMessage of errorMessages"
          >{{ errorMessage }}</span
        >
      </p>
    </label>
  </div>
</div>
