import { Observable } from "rxjs";

import { BaseFilter } from "./bases/base-filter";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";
import {
  RestrictionRequestDto,
  RestrictionResponseDto,
} from "./dto/restriction.dto";

export interface JuridicRestrictionFilter extends BaseFilter {
  id?: string;
}

export abstract class JuridicRestrictionData {
  abstract list(
    juridicId: string,
    filter: JuridicRestrictionFilter
  ): Observable<BaseResponse<RestrictionResponseDto[], BaseResponsePagination>>;
  abstract getById(
    id: string
  ): Observable<BaseResponse<RestrictionResponseDto, BaseResponsePagination>>;
  abstract insert(
    juridicId: string,
    request: RestrictionRequestDto
  ): Observable<any>;
  abstract delete(id: string): Observable<any>;
}
