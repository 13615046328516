import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { FormService } from 'src/app/@core/service/form.service';

@Component({
  selector: 'ngx-observations-select',
  templateUrl: './observations-select.component.html',
  styleUrls: ['./observations-select.component.scss']
})
export class ObservationsSelectComponent {
  @Input() label?: string;
  @Input() title: string;
  @Input() placeholder?: string;
  @Input() data?: any;

  constructor(public formService: FormService, protected ref: NbDialogRef<ObservationsSelectComponent>) {
  }

  onSave() {
    this.ref.close(this.data)
  }

  close() {
    this.ref.close()
  }
}
