import { Observable } from "rxjs";

import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "../../../environments/environment";
import {
  BaseResponse,
  BaseResponsePagination,
} from "../data/bases/base-response";
import { Document, DocumentData, DocumentFilter } from "../data/document";
import { setBaseFilter } from "../utils/http/base-http-filter";
import { ParticipantTypeDeleteEnum } from "../utils/enum/participant-type-delete.enum";

@Injectable()
export class DocumentService extends DocumentData {
  private baseUrl = environment.services.accessControl.baseUrl;

  constructor(private http: HttpClient) {
    super();
  }

  list(
    filter: DocumentFilter
  ): Observable<BaseResponse<Document[], BaseResponsePagination>> {
    var params = setBaseFilter(filter);

    if (filter.id) params = params.set("id", filter.id);
    if (filter.name) params = params.set("name", filter.name);
    if (filter.personType) params = params.set("personType", filter.personType);

    return this.http.get<BaseResponse<Document[], BaseResponsePagination>>(
      `${this.baseUrl}/api/v1/document`,
      { params }
    );
  }

  update(document: Document) {
    return this.http.put(
      `${this.baseUrl}/api/v1/document/${document.id}`,
      document
    );
  }

  delete(id: string) {
    return this.http.delete(`${this.baseUrl}/api/v1/document/${id}`);
  }

  insert(document: Document) {
    return this.http.post(`${this.baseUrl}/api/v1/document/`, document);
  }
}
