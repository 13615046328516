import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import { LocalDataSource } from "ng2-smart-table";
import { DialogComponent } from "../../dialogs/base/dialog.component";
import { DialogDescription, DialogTitle } from "../../../../@core/utils/enum/message.enum";
import { environment } from "../../../../../environments/environment";
import { ActionsDropdownComponent } from "../actions-dropdown/actions-dropdowns.component";
import { License, LicenseFuncionality, LicenseModule } from "../../../../@core/data/bases/base-model";
import { ModuleSelectComponent } from "../../../../pages/access/structure/module/module-select/module-select.component";
import { FuncionalitySelectComponent } from "../../../../pages/access/structure/funcionality/funcionality-select/funcionality-select.component";

@Component({
  selector: 'ngx-access-license',
  templateUrl: './access-license.component.html',
  styleUrls: ['./access-license.component.scss'],
})
export class AccessLicenseComponent implements OnInit {
  @Input() modules: LicenseModule[] = []
  @Input() funcionalities: LicenseFuncionality[] = []
  sourceModule: LocalDataSource = new LocalDataSource();
  sourceFunctionality: LocalDataSource = new LocalDataSource();
  result: License = {
    modules: [],
    funcionalities: []
  }

  constructor(
    private dialogService: NbDialogService,
  ) { }

  ngOnInit(): void {
    this.onListModules()
    this.onListFuncionalities()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['modules']) {
      this.onListModules()
    }

    if (changes['funcionalities']) {
      this.onListFuncionalities()
    }
  }

  popupModule() {
    this.dialogService.open(ModuleSelectComponent, {
      context: {
        contains: this.modules
      }
    })
      .onClose.subscribe(addModule => {
        if (addModule) {
          this.modules.push({ ...addModule, level: 0 })
          this.onListModules()
        }
      });
  }

  onListModules() {
    this.sourceModule.load(this.modules)
  }

  removeModule(moduleId) {
    const index = this.modules?.findIndex(item => item.id === moduleId);
    if (index !== -1) {
      this.modules?.splice(index, 1);
    }
    this.onListModules();
  }

  removeFuncionality(funcionalityId) {
    const index = this.funcionalities?.findIndex(item => item.id === funcionalityId);
    if (index !== -1) {
      this.funcionalities?.splice(index, 1);
    }
    this.onListFuncionalities();
  }

  onDeleteModule(event) {
    this.dialogService.open(DialogComponent, {
      context: {
        title: DialogTitle.Delete,
        description: DialogDescription.Delete,
        records: [...[event.data.name]],
      },
    }).onClose.subscribe(confirm => {
      if (confirm) {
        this.removeModule(event.data.id)
      }
    })
  }

  onDeleteFuncionality(event) {
    this.dialogService.open(DialogComponent, {
      context: {
        title: DialogTitle.Delete,
        description: DialogDescription.Delete,
        records: [...[event.data.name]],
      },
    }).onClose.subscribe(confirm => {
      if (confirm) {
        this.removeFuncionality(event.data.id)
      }
    })
  }

  popupFuncionality() {
    this.dialogService.open(FuncionalitySelectComponent, {
      context: {
        contains: this.funcionalities
      }
    })
      .onClose.subscribe(addFunctionality => {
        if (addFunctionality) {
          this.funcionalities.push({ ...addFunctionality, level: 0 })
          this.onListFuncionalities()
        }
      });
  }

  onListFuncionalities() {
    this.sourceFunctionality.load(this.funcionalities)
  }

  getLicense() {
    this.result.modules = this.modules
    this.result.funcionalities = this.funcionalities
    return this.result
  }

  settingModule = {
    pager: {
      perPage: environment.pages.permissions.recordsPerPage,
      display: false
    },
    mode: 'external',
    hideSubHeader: true,
    selectedRowIndex: -1,
    actions: {
      columnTitle: "",
      add: false,
      edit: false,
      delete: true,
      position: 'right'
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      id: {
        title: 'Id',
        hide: true
      },
      name: {
        title: 'Módulo',
      },
      level: {
        title: 'Permissão',
        type: 'custom',
        renderComponent: ActionsDropdownComponent,
        valuePrepareFunction: (cell, row) => row,
        onComponentInitFunction: (instance: ActionsDropdownComponent) => {
          instance.valueChange.subscribe(({ value, rowData }) => {
            rowData.level = value;
            this.sourceModule.update(rowData, rowData);
          });
        }
      }
    }
  };

  settingFuncionality = {
    pager: {
      perPage: environment.pages.permissions.recordsPerPage,
      display: false
    },
    mode: 'external',
    hideSubHeader: true,
    selectedRowIndex: -1,
    actions: {
      columnTitle: "",
      add: false,
      edit: false,
      delete: true,
      position: 'right'
    },
    delete: {
      deleteButtonContent: '<i class="nb-trash"></i>',
      confirmDelete: true,
    },
    columns: {
      id: {
        title: 'Id',
        hide: true
      },
      module: {
        title: 'Módulo',
        valuePrepareFunction: (cell, row) => {
          return row.module.name;
        }
      },
      name: {
        title: 'Funcionalidade',
      },
      level: {
        title: 'Permissão',
        type: 'custom',
        renderComponent: ActionsDropdownComponent,
        valuePrepareFunction: (cell, row) => row,
        onComponentInitFunction: (instance: ActionsDropdownComponent) => {
          instance.valueChange.subscribe(({ value, rowData }) => {
            rowData.level = value;
            this.sourceFunctionality.update(rowData, rowData);
          });
        }
      }
    }
  };
}
