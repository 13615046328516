<nb-card>
  <nb-card-header>{{ title }}</nb-card-header>
  <nb-card-body>
    <label class="label">{{ label }}</label>
    <textarea
      nbInput
      fullWidth
      [placeholder]="placeholder"
      [(ngModel)]="data"
    ></textarea>
  </nb-card-body>
  <nb-card-footer>
    <div class="row flex">
      <div class="col-12 flex justify-content-end">
      <button status="primary" class="mr-3" nbButton (click)="onSave()">Salvar</button>
      <button status="basic" nbButton (click)="close()">Fechar</button>
      </div>
    </div>
  </nb-card-footer>
</nb-card>
