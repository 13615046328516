import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import {
  AgreementDocumentData,
  AgreementDocumentFilter,
} from "../data/agreement-document";
import {
  BaseResponse,
  BaseResponsePagination,
} from "../data/bases/base-response";
import {
  AgreementDocumentItemListResponseDto,
  AgreementDocumentRequestDto,
  AgreementDocumentResponseDto,
} from "../data/dto/agreement-document.dto";
import { mockAgreementDocument } from "../mock/agreement.mock";
import { setBaseFilter } from "../utils/http/base-http-filter";

@Injectable()
export class AgreementDocumentService extends AgreementDocumentData {
  private baseUrl = `${environment.services.project.baseUrl}/api/v1/document`;

  constructor(private http: HttpClient) {
    super();
  }

  list(
    filter: AgreementDocumentFilter
  ): Observable<
    BaseResponse<AgreementDocumentItemListResponseDto[], BaseResponsePagination>
  > {
    var params = setBaseFilter(filter);
    if (filter.agreementId)
      params = params.set("agreementId", filter.agreementId);
    return this.http.get<
      BaseResponse<
        AgreementDocumentItemListResponseDto[],
        BaseResponsePagination
      >
    >(`${this.baseUrl}`, { params });
  }
  getById(
    id: string
  ): Observable<
    BaseResponse<AgreementDocumentResponseDto, BaseResponsePagination>
  > {
    // return this.http.get<
    //   BaseResponse<AgreementDocumentResponseDto, BaseResponsePagination>
    // >(`${this.baseUrl}/${id}`);
    return null;
  }
  update(id: string, request: AgreementDocumentRequestDto) {
    return this.http.put(`${this.baseUrl}/${id}/`, request);
  }
  delete(id: any) {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }
  insert(formData: FormData): Observable<any> {
    return this.http.post(`${this.baseUrl}`, formData);
  }
}
