import { environment } from "../../../../../environments/environment";

export const PjSelectTableSettings = {
  pager: {
    perPage: environment.pages.selects.recordsPerPage,
    display: false,
  },
  mode: "external",
  hideSubHeader: true,
  selectedRowIndex: -1,
  actions: {
    columnTitle: "",
    add: false,
    edit: false,
    delete: false,
    position: "right",
  },
  noDataMessage: "Não há pessoa júridica cadastrada.",
  rowClassFunction: (row) => {
    return "cursor-pointer";
  },
  columns: {
    id: {
      title: "Id",
      hide: true,
    },
    cnpj: {
      title: "CNPJ",
    },
    companyName: {
      title: "Razão Social",
    },
    comercialName: {
      title: "Nome Fantasia",
    },
    types: {
      title: "Tipo",
      valuePrepareFunction: (value) => {
        return value.map((type) => type.name).join(", ");
      },
    },
    activity: {
      title: "Atividade",
    },
    segment: {
      title: "Segmento",
    },
  },
};
