import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FinancierPaymentRequestDto, FinancierPaymentResponseDto } from '../data/dto/financier.dto';
import { Observable } from 'rxjs';
import { BaseResponse, BaseResponsePagination } from '../data/bases/base-response';
import { FinancierPaymentData } from '../data/financier-payment';
import { environment } from 'src/environments/environment';

type NewType = HttpClient;

@Injectable({
  providedIn: 'root'
})
export class FinancierPaymentService extends FinancierPaymentData {

private baseUrl = `${environment.services.project.baseUrl}/api/v1/financier-payment`;

constructor(private http: HttpClient) {
    super()
  }

  getById(id: string): Observable<BaseResponse<FinancierPaymentResponseDto, BaseResponsePagination>> {
    return this.http.get<
      BaseResponse<FinancierPaymentResponseDto, BaseResponsePagination>
    >(`${this.baseUrl}/${id}`);
  }

  update(id: string, request: FinancierPaymentRequestDto) {
    return this.http.put<FinancierPaymentRequestDto>(`${this.baseUrl}/${id}`, request)
  }
}
