import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FinancierBankData } from '../data/financier-bank-data';
import { BaseResponse, BaseResponsePagination } from '../data/bases/base-response';
import { FinancierAccountRequestDto, FinancierAccountResponseDto, FinancierRequestDto, FinancierResponseDto } from '../data/dto/financier.dto';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FinancierBankDataService extends FinancierBankData {

  private baseUrl = `${environment.services.project.baseUrl}/api/v1/financier-banking-information`;

  constructor(private http: HttpClient) {
    super()
  }

  getById(id: string): Observable<BaseResponse<FinancierAccountResponseDto, BaseResponsePagination>> {
    return this.http.get<
      BaseResponse<FinancierAccountResponseDto, BaseResponsePagination>
    >(`${this.baseUrl}/${id}`);
  }

  update(id: string, request: FinancierAccountRequestDto) {
    return this.http.put<FinancierRequestDto>(`${this.baseUrl}/${id}`, request)
  }
}
