import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseResponse, BaseResponsePagination } from '../data/bases/base-response';
import { AddressRequestDto, AddressResponseDto } from '../data/dto/address.dto';
import { JuridicAddressData, JuridicAddressFilter } from '../data/juridic-address';
import { setBaseFilter } from '../utils/http/base-http-filter';

@Injectable()
export class JuridicAddressService extends JuridicAddressData {
  private baseUrl = `${environment.services.accessControl.baseUrl}/api/v1/juridic_address`;

  constructor(private http: HttpClient) {
    super();
  }

  getById(
    id: string
  ): Observable<BaseResponse<AddressResponseDto, BaseResponsePagination>> {
    return this.http.get<
      BaseResponse<AddressResponseDto, BaseResponsePagination>
    >(`${this.baseUrl}/${id}`);
  }

  list(
    juridicId: string,
    filter: JuridicAddressFilter
  ): Observable<BaseResponse<AddressResponseDto[], BaseResponsePagination>> {
    var params = setBaseFilter(filter);
    if (filter.id) params = params.set("id", filter.id);

    return this.http.get<
      BaseResponse<AddressResponseDto[], BaseResponsePagination>
    >(`${this.baseUrl}/by-juridic/${juridicId}`, { params });
  }
  update(id: string, request: AddressRequestDto): Observable<any> {
    return this.http.put(`${this.baseUrl}/${id}/`, request);
  }
  insert(juridicId: string, request: AddressRequestDto): Observable<any> {
    return this.http.post(`${this.baseUrl}/${juridicId}`, {
      ...request,
      id: null,
    });
  }
  delete(id: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }
}
