import { JuridicType } from "../juridic_type";

export interface JuridicRequestDto {
  id?: string;
  cnpj: string;
  abbreviation: string;
  companyName: string;
  comercialName: string;
  stateRegistration: string;
  municipalRegistration: string;
  segment: string;
  activity: string;
  nationality: string;
  section: string;
  realm: string;
  mei: boolean;
  website: string;
  socialNetwork: string;
  reason: string;
  mecMctiRegister: string;
  reimbursementLegislation: string;
  ordinanceDate: string;
  mecMctiLink: string;
  initialTerm: string;
  endTerm: string;
  isActive: boolean;
  types: string[];
}
export interface JuridicResponseDto {
  id?: string;
  cnpj: string;
  abbreviation: string;
  companyName: string;
  comercialName: string;
  stateRegistration: string;
  municipalRegistration: string;
  segment: string;
  activity: string;
  nationality: string;
  section: string;
  realm: string;
  mei: boolean;
  website: string;
  socialNetwork: string;
  reason: string;
  mecMctiRegister: string;
  reimbursementLegislation: string;
  ordinanceDate: string;
  mecMctiLink: string;
  initialTerm: string;
  endTerm: string;
  isActive: boolean;
  types: JuridicType[];
}
export interface JuridicItemListResponseDto {
  id?: string;
  cnpj: string;
  companyName: string;
  comercialName: string;
  segment: string;
  activity: string;
  isActive: boolean;
  types: JuridicType[];
}
