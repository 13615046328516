import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { BaseResponse, BaseResponsePagination } from "../data/bases/base-response";
import { ProvisionCount, ProvisionCountData, ProvisionCountFilter } from "../data/provision";

@Injectable()
export class ProvisionCountService extends ProvisionCountData {
  private baseUrl = environment.services.accounting.baseUrl;


  constructor(private http:HttpClient) { super() }

  list(filter:ProvisionCountFilter, pagination:any): Observable<BaseResponse<ProvisionCount[],BaseResponsePagination>> {
    let params = new HttpParams()
      .set('page', pagination.currentPage)
      .set('page_size', pagination.pageSize);

    if(filter.id)
      params = params.set('id', filter.id)

    if(filter.name)
      params = params.set('name', filter.name)

    params = params.set('orderBy', '').set('sortedBy', '');

    return this.http.get<BaseResponse<ProvisionCount[],BaseResponsePagination>>(`${this.baseUrl}/api/provision/`, { params })
  }

  insert(provisionCount:ProvisionCount){
    return this.http.post(`${this.baseUrl}/api/provision/`,provisionCount)
  }

  delete(id){
    return this.http.delete(`${this.baseUrl}/api/provision/${id}`,id)
  }

  update(provisionCount:ProvisionCount){
    const provisionCountData = { "name": provisionCount.name };
    return this.http.put(`${this.baseUrl}/api/provision/${provisionCount.id}/`,provisionCountData)
  }

  get(id) {
    var result = this.http.get(`${this.baseUrl}/api/provision/${id}/`,id)
    return result;
  }

  getProvision() {
    var result = this.http.get(`${this.baseUrl}/api/provision/`)
    return result;
  }
}
 