import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

import { UploadFile } from "./upload-file.interface";

@Component({
  selector: "ngx-upload-file",
  templateUrl: "./upload-file.component.html",
  styleUrls: ["./upload-file.component.scss"],
})
export class UploadFileComponent implements OnInit {
  @ViewChild("fileDropRef", { static: false }) fileDropEl!: ElementRef;
  public showUpload: boolean = true;
  public message!: string;
  public maxUploadSizeBytes: number = 0;

  @Input() public files: UploadFile[] = [];
  @Input() public errorMessages!: string[];
  @Input() public multiple: boolean = false;
  @Input() public maxUploadSize!: string;
  @Input() public maxFiles!: number;
  @Input() public extensions: string[] = [];

  @Output() public hanldeFileUploaded: EventEmitter<UploadFile[]> =
    new EventEmitter<UploadFile[]>();
  @Output() public hanldeDeleteFile: EventEmitter<UploadFile[]> =
    new EventEmitter<UploadFile[]>();

  ngOnInit(): void {
    this.extensions = this.extensions.map((ext) => ext.toLocaleLowerCase());
    this.maxUploadSizeBytes = this.convertToBytes();
  }

  private convertToBytes(): number {
    let bytes = 0;

    if (this.maxUploadSize) {
      const groups = /([\d,.]{1,})([a-zA-z]{0,})/.exec(this.maxUploadSize);

      const value = +groups![1].replace(",", ".");
      const unit = groups![2];
      switch (unit.toLocaleLowerCase()) {
        case "":
          bytes = value;
          break;
        case "kb":
          bytes = value * 1024;
          break;
        case "mb":
          bytes = value * 1024 * 1024;
          break;
        case "gb":
          bytes = value * 1024 * 1024 * 1024;
          break;
        case "tb":
          bytes = value * 1024 * 1024 * 1024 * 1024;
          break;
        default:
          throw new Error(`Unidade de tamanho ${unit} inválida`);
      }
    }
    return bytes;
  }

  public onFileDropped($event: any) {
    this.prepareFilesList($event);
  }
  public fileBrowseHandler($event: any) {
    this.prepareFilesList($event.target.files);
  }
  // public uploadFilesSimulator(index: number) {
  //   setTimeout(() => {
  //     if (index === this.files.length) {
  //       return;
  //     } else {
  //       const progressInterval = setInterval(() => {
  //         if (this.files[index].progress === 100) {
  //           clearInterval(progressInterval);
  //           this.uploadFilesSimulator(index + 1);
  //         } else {
  //           this.files[index].progress += 5;
  //         }
  //       }, 200);
  //     }
  //   }, 1000);
  // }

  public deleteFile(index: number) {
    if (this.files[index].progress < 100) {
      return;
    }
    this.files.splice(index, 1);
    this.showUpload = true;
    this.hanldeDeleteFile.emit(this.files);
  }
  public prepareFilesList(files: UploadFile[]) {
    this.message = "";
    try {
      const localfiles: UploadFile[] = [];
      let hasError = false;
      for (const item of files) {
        let hasErrorFileSize = false,
          hasErrorExtension = false,
          hasErrorMaxFiles = false;

        hasErrorFileSize = !this.checkFileSize(item);
        hasErrorExtension = !this.checkExtension(item);
        hasErrorMaxFiles = !this.checkMaxFiles(
          this.files.length + localfiles.length + 1
        );
        item.progress = 100;

        if (hasErrorFileSize || hasErrorExtension || hasErrorMaxFiles)
          hasError = true;
        else localfiles.push(item);
      }

      if (hasError) return;

      this.files = [...this.files, ...localfiles];
      this.fileDropEl.nativeElement.value = "";
      this.showUpload =
        this.multiple && (this.files.length < this.maxFiles || !this.maxFiles);
      this.hanldeFileUploaded.emit(this.files);
    } catch (e: any) {
      this.message = e.message;
    }
  }
  public formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  public checkMaxFiles(totalFiles: number) {
    if (!this.multiple && totalFiles > 1) {
      this.message = `Envie somente 1 arquivo`;
      return false;
    }

    if (!!this.multiple && totalFiles > this.maxFiles) {
      this.message = `Envie no máximo ${this.maxFiles} arquivos`;
      return false;
    }
    return true;
  }

  public checkExtension(file: File) {
    const extension = file.name
      .substring(file.name.lastIndexOf("."))
      .toLocaleLowerCase();

    if (this.extensions?.length > 0 && !this.extensions.includes(extension)) {
      this.message = `Envie arquivos do tipo ${this.extensions
        .join(", ")
        .toLocaleUpperCase()}`;
      return false;
    }
    return true;
  }

  public checkFileSize(file: File) {
    if (!!this.maxUploadSizeBytes && this.maxUploadSizeBytes < file.size) {
      this.message = `Tamanho de arquivo excedido.`;
      return false;
    }
    return true;
  }
  public deleteAllFiles() {
    this.files = [];
    this.showUpload = true;
  }

  // public getDescription() {
  //   let description = "Envie ";
  //   if (this.maxFiles) {
  //     description += `no máximo ${!!this.multiple ? this.maxFiles : 1}`;
  //     if (this.multiple) {
  //       description += this.maxFiles > 1 || !this.maxFiles ? "s " : " ";
  //     }
  //   }

  //   description += ` ${this.extensions.join(" ").toLocaleUpperCase()}`;

  //   if (this.maxUploadSizeBytes) {
  //     if (!!this.maxUploadSize) {
  //       description += ` até ${this.formatBytes(this.maxUploadSizeBytes)}`;
  //     }

  //     return description;
  //   }
  // }
  public getDescription() {
    let description = "Envie ";

    if (this.maxFiles) {
      const maxFilesCount = !!this.multiple ? this.maxFiles : 1;
      description += `no máximo ${maxFilesCount} arquivo${
        maxFilesCount > 1 ? "s" : ""
      } `;
    }

    description += `${this.extensions.join(" ").toLocaleUpperCase()}`;

    if (this.maxUploadSizeBytes) {
      description += ` até ${this.formatBytes(this.maxUploadSizeBytes)}`;
    }

    return description;
  }
}
