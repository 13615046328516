export interface FinancierRequestDto {
    agreementId: string;
    juridicId: string;
    situation: string;
    juridicOrgan: string;
    juridicSphere: string;
    juridicNationality: string;
    resourceSource: string;
    transferGov: string;
    publicAgency: string;
    amount: number;
    usesIncome: boolean;
    rules?: string;
    approvingAuthority?: string;
    isActive: boolean;
}

export interface FinancierResponseDto {
    id: string;
    juridicId: string;
    agreementId: string;
    situation: string;
    juridicOrgan: string;
    juridicSphere: string;
    juridicNationality: string;
    resourceSource: string;
    transferGov: string;
    publicAgency: string;
    amount: number;
    installments: number;
    usesIncome: boolean;
    rules: string;
    approvingAuthority: string;
    isActive: boolean;
}

export interface FinancierPaymentInstallments {
    installment: number;
    restrictionType: string;
    observation: string;
}

export interface FinancierPaymentRequestDto {
    id?: string;
    installments: number;
    financialSchedule: boolean;
    financialScheduleObservation: string;
    fundingReceiptDocumentation: string;
    fundingReceiptDocumentationObservation: string;
    paymentHasRestriction: boolean;
    financierPaymentInstallments: FinancierPaymentInstallments[];
}

export interface FinancierPaymentResponseDto {
    id?: string;
    amount: number;
    installments: number;
    financialSchedule: boolean,
    financialScheduleObservation: string;
    fundingReceiptDocumentation: string;
    fundingReceiptDocumentationObservation: string;
    paymentHasRestriction: boolean,
    financierPaymentInstallments: FinancierPaymentInstallments[]
}
export interface FinancierAccountRequestDto {
    investmentType: string;
    openingConditions: string;
    bankName: string;
    bankCode?: string;
    agency: string;
    accountNumber: string;
}

export interface FinancierAccountResponseDto {
    id?: string;
    investmentType: string;
    openingConditions: string;
    bankName: string;
    bankCode: string;
    agency: string;
    accountNumber: string;
}

export interface FinancierRestrictionRequestDto {
    name: string;
    financierRestrictionId: string;
}

export interface FinancierRestrictionResponseDto {
    name: string;
    financierRestrictionId: string;
}

export interface FinancierFinancierRestrictionRequestDto {
    id: string;
    name: string;
    isActive: boolean;
}

export interface FinancierFinancierRestrictionResponseDto {
    id: string;
    name: string;
    isActive: boolean;
}

export interface FinancierSchedules {
    installment: number;
    amount: number;
    scheduleDate: string;
    rubricId: number;
    rubricName: string;
}

export interface FinancierScheduleRequestDto {
    id?: string;
    financierSchedules: FinancierSchedules[];
}

export interface FinancierScheduleResponseDto {
    id?: string;
    amount: number;
    installments: number;
    financierSchedules: FinancierSchedules[];
    plannedTotal: number;
}
