import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { LOCALE_ID, NgModule } from "@angular/core";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { CoreModule } from "./@core/core.module";
import { ThemeModule } from "./@theme/theme.module";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import "./@core/utils/prototype";
import {
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
} from "@nebular/theme";
import { NbAuthModule, NbAuthService } from "@nebular/auth";
import { Router } from "@angular/router";
import { IConfig } from "ngx-mask";
import ptBr from "@angular/common/locales/pt";
import { registerLocaleData } from "@angular/common";
import { AuthInterceptor } from "./@core/utils/http/auth-interceptor";
import { ToastService } from "./@core/utils/toast/toast.service";

// registerlocaledata
export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;
registerLocaleData(ptBr);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    CoreModule.forRoot(),
    ThemeModule.forRoot(),
    NbAuthModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    ToastService,
    { provide: LOCALE_ID, useValue: "pt" },
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: function (
        router: Router,
        authService: NbAuthService,
        toastService: ToastService
      ) {
        return new AuthInterceptor(router, authService, toastService);
      },
      multi: true,
      deps: [Router, NbAuthService, ToastService],
    },
  ],
})
export class AppModule {}
