import { FormService } from 'src/app/@core/service/form.service';

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: "ngx-input",
  templateUrl: "./input.component.html",
  styleUrls: ["./input.component.scss"],
})
export class InputComponent extends HTMLInputElement {
  @Input() label: string;
  @Input() formControlName: string;
  // @Input() form: FormGroup;
  constructor(public formService: FormService) {
    super();
  }

}
