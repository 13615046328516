import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import {
  BaseResponse,
  BaseResponsePagination,
} from "../data/bases/base-response";
import {
  ParticipantListResponseDto,
  ParticipantRequestDto,
  ParticipantResponseDto,
} from "../data/dto/participant.dto";
import { ParticipantData, ParticipantFilter } from "../data/participant";
import { setBaseFilter } from "../utils/http/base-http-filter";
import { ParticipantTypeDeleteEnum } from "../utils/enum/participant-type-delete.enum";

@Injectable()
export class ParticipantService extends ParticipantData {
  private baseUrl = `${environment.services.project.baseUrl}/api/v1/participant`;

  constructor(private http: HttpClient) {
    super();
  }

  list(
    filter: ParticipantFilter
  ): Observable<
    BaseResponse<ParticipantListResponseDto[], BaseResponsePagination>
  > {
    var params = setBaseFilter(filter);
    if (filter.agreementId)
      params = params.set("agreementId", filter.agreementId);

    return this.http.get<
      BaseResponse<ParticipantListResponseDto[], BaseResponsePagination>
    >(`${this.baseUrl}`, { params });
  }
  getById(
    id: string
  ): Observable<BaseResponse<ParticipantResponseDto, BaseResponsePagination>> {
    return this.http.get<
      BaseResponse<ParticipantResponseDto, BaseResponsePagination>
    >(`${this.baseUrl}/${id}`);
  }
  update(id: string, request: ParticipantRequestDto) {
    return this.http.put(`${this.baseUrl}/${id}/`, request);
  }
  delete(id: any, type: ParticipantTypeDeleteEnum) {
    return this.http.delete(`${this.baseUrl}/${id}/${type}`);
  }
  insert(request: ParticipantRequestDto) {
    return this.http.post<BaseResponse<string, BaseResponsePagination>>(
      `${this.baseUrl}`,
      {
        ...request,
        id: null,
      }
    );
  }
}
