import { Observable } from "rxjs";

import { BaseFilter } from "./bases/base-filter";
import { BaseModel } from "./bases/base-model";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";

export interface AgreementType extends BaseModel {
  id?: string;
  name?: string;
}

export interface AgreementTypeFilter extends AgreementType, BaseFilter {}

export abstract class AgreementTypeData {
  abstract list(
    filter: AgreementTypeFilter
  ): Observable<BaseResponse<AgreementType[], BaseResponsePagination>>;
}
