import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FinancierData } from '../data/financier';
import { FinancierRequestDto, FinancierResponseDto } from '../data/dto/financier.dto';
import { BaseResponse, BaseResponsePagination } from '../data/bases/base-response';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FinancierService extends FinancierData {

  private baseUrl = `${environment.services.project.baseUrl}/api/v1/financier`;

  protected financierIdSource = new BehaviorSubject<any>('');
  get currentData(): Observable<any> {
    return this.financierIdSource.asObservable();
  }

  constructor(private http: HttpClient) {
    super()
  }

  insert(request: FinancierRequestDto) {
    return this.http.post<FinancierRequestDto>(`${this.baseUrl}`, {
      ...request,
      id: null
    })
  }

  get(): Observable<BaseResponse<FinancierResponseDto[], BaseResponsePagination>> {
    return this.http.get<
      BaseResponse<FinancierResponseDto[], BaseResponsePagination>
    >(`${this.baseUrl}`);
  }

  getById(id: string): Observable<BaseResponse<FinancierResponseDto, BaseResponsePagination>> {
    return this.http.get<
      BaseResponse<FinancierResponseDto, BaseResponsePagination>
    >(`${this.baseUrl}/${id}`);
  }

  update(id: string, request: FinancierRequestDto) {
    return this.http.put<FinancierRequestDto>(`${this.baseUrl}/${id}`, request)
  }

  updateFinancierId(id: string) {
    this.financierIdSource.next(id);
  }
}
