import { Observable } from "rxjs";

import { BaseFilter } from "./bases/base-filter";
import { BaseModel } from "./bases/base-model";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";

export interface Modality extends BaseModel {
  id?: string;
  name?: string;
}

export interface ModalityFilter extends Modality, BaseFilter {}

export abstract class ModalityData {
  abstract list(
    filter: ModalityFilter
  ): Observable<BaseResponse<Modality[], BaseResponsePagination>>;
}
