import { Injectable } from '@angular/core';
import { FinancierRestrictionData } from '../data/financier-restriction';
import { HttpClient } from '@angular/common/http';
import { FinancierRestrictionRequestDto, FinancierRestrictionResponseDto } from '../data/dto/financier.dto';
import { BaseResponse, BaseResponsePagination } from '../data/bases/base-response';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FinancierRestrictionService extends FinancierRestrictionData {

  private baseUrl = `${environment.services.project.baseUrl}/api/v1/financier-restriction`;

  constructor(private http: HttpClient) {
    super()
  }
  
  insert(request: FinancierRestrictionRequestDto) {
    return this.http.post<FinancierRestrictionRequestDto>(`${this.baseUrl}`, {
      ...request,
      id: null
    })
  }

  list(): Observable<BaseResponse<FinancierRestrictionResponseDto[], BaseResponsePagination>> {
    return this.http.get<
      BaseResponse<FinancierRestrictionResponseDto[], BaseResponsePagination>
    >(`${this.baseUrl}`);
  }

  update(id: string, request: FinancierRestrictionRequestDto) {
    return this.http.put<FinancierRestrictionRequestDto>(`${this.baseUrl}/${id}`, request)
  }

  delete(id: string) {
    return this.http.delete(`${this.baseUrl}/${id}`)
  }
}
