<form (ngSubmit)="confirmBankInformation(form)" #form="ngForm" aria-labelledby="title">
  <nb-card>
    <nb-card-header>Informe o dado bancário</nb-card-header>
    <nb-card-body>
      <div class="form-group">
        <label for="labelBank" class="label">Banco</label>
        <nb-select selected="1" fullWidth [(ngModel)]="bankInformations.bankId" #bankId="ngModel" name="bankId"
          id="dropd-bank" autofocus [status]="bankId.dirty ? (bankId.invalid ? 'danger' : 'success') : 'basic'"
          placeholder="Banco" [required]="true" [attr.aria-invalid]="bankId.invalid && bankId.touched ? true : null">
          <nb-option *ngFor="let bank of banks" value="{{ bank.id }}">{{
            bank.name
            }}</nb-option>
        </nb-select>
        <ng-container *ngIf="bankId.invalid && bankId.touched">
          <p class="caption status-danger" *ngIf="bankId.errors?.required">
            Banco é obrigatório
          </p>
        </ng-container>
      </div>

      <div class="form-group">
        <label id="inputAgency" class="label">Agência (sem dígito)</label>
        <input nbInput fullWidth [(ngModel)]="bankInformations.agency" #agency="ngModel" name="agency" id="ipt-ag"
          placeholder="Agência - sem dígito" autofocus [required]="true"
          [status]="agency.dirty ? (agency.invalid ? 'danger' : 'success') : 'basic'"
          [attr.aria-invalid]="agency.invalid && agency.touched ? true : null" />
        <ng-container *ngIf="agency.invalid && agency.touched">
          <p class="caption status-danger" *ngIf="agency.errors?.required">
            Agência é obrigatório
          </p>
        </ng-container>
      </div>

      <div class="form-group">
        <label for="labelAccountType" class="label">Tipo de conta</label>
        <nb-select selected="1" fullWidth [(ngModel)]="bankInformations.accountTypeId" #accountTypeId="ngModel"
          name="accountType" id="dropd-accountType" autofocus
          [status]=" accountTypeId.dirty ? accountTypeId.invalid ? 'danger': 'success': 'basic'"
          placeholder="Tipo de Conta" [required]="true"
          [attr.aria-invalid]=" accountTypeId.invalid && accountTypeId.touched ? true : null">
          <nb-option *ngFor="let accountType of accountTypes" value="{{ accountType.id }}">{{ accountType.name
            }}</nb-option>
        </nb-select>
        <ng-container *ngIf="accountTypeId.invalid && bankId.touched">
          <p class="caption status-danger" *ngIf="accountTypeId.errors?.required">
            Tipo de Conta é obrigatório
          </p>
        </ng-container>
      </div>

      <div class="form-group">
        <label id="inputAccount" class="label">Conta</label>
        <input nbInput fullWidth [(ngModel)]="bankInformations.account" #account="ngModel" name="account"
          id="ipt-account" placeholder="Conta - sem dígito" [required]="true" [status]="
            account.dirty ? (account.invalid ? 'danger' : 'success') : 'basic'
          " [attr.aria-invalid]="account.invalid && account.touched ? true : null" />
        <ng-container *ngIf="account.invalid && account.touched">
          <p class="caption status-danger" *ngIf="account.errors?.required">
            Conta é obrigatório
          </p>
          <p class="caption status-danger" *ngIf="account.errors?.invalid">
            Já existe uma conta cadastrada com esse número.
          </p>
        </ng-container>
      </div>
    </nb-card-body>

    <nb-card-footer>
      <button type="submit" nbButton status="primary" class="button" id ="btn-confirm" [disabled]="submitted || !form.valid"
        [class.btn-pulse]="submitted">
        Confirmar
      </button>
      <button type="reset" nbButton class="button" id ="btn-close" (click)="close()">
        Fechar
      </button>
    </nb-card-footer>
  </nb-card>
</form>