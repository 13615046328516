import { of as observableOf } from "rxjs";

import { CommonModule } from "@angular/common";
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from "@angular/core";
import {
  NbAuthJWTToken,
  NbAuthModule,
  NbPasswordAuthStrategy,
} from "@nebular/auth";
import { NbRoleProvider, NbSecurityModule } from "@nebular/security";

import { environment } from "../../environments/environment";
import { AuthGuard } from "../auth/auth-guard.service";
import { AuthModule } from "../auth/auth.module";
import { AccountData } from "./data/account";
import { AccountTypeData } from "./data/account-type";
import { AssetReductionData } from "./data/asset-reduction";
import { AuthData } from "./data/auth";
import { BankData } from "./data/bank";
import { ClientData } from "./data/client";
import { CommonData } from "./data/common";
import { ContextData } from "./data/context";
import { CostCenterData } from "./data/cost-center";
import { CreditSummaryData } from "./data/credit-summary";
import { DailyConsolidationData } from "./data/daily-consolidation";
import { DemonstrativeData } from "./data/demonstrative";
import { DocumentData } from "./data/document";
import { FuncionalityData } from "./data/funcionality";
import { JuridicData } from "./data/juridic";
import { JuridicAccountData } from "./data/juridic-account";
import { JuridicAddressData } from "./data/juridic-address";
import { JuridicContactData } from "./data/juridic-contact";
import { JuridicDocumentData } from "./data/juridic-document";
import { JuridicPartnerData } from "./data/juridic-partner";
import { JuridicRestrictionData } from "./data/juridic-restriction";
import { JuridicTypeData } from "./data/juridic_type";
import { LocalStorageData } from "./data/local-storage";
import { MenuData } from "./data/menu";
import { ModuleData } from "./data/module";
import { NatureCountData } from "./data/nature-count";
import { ParamData } from "./data/params";
import { PersonData } from "./data/person";
import { ProfilesData } from "./data/profiles";
import { ProvisionCountData } from "./data/provision";
import { RestrictionData } from "./data/restriction";
import { TaxData } from "./data/tax";
import { TransactionData } from "./data/transaction";
//Datas
import { UserData } from "./data/user";
import { UserAccountData } from "./data/user-account";
import { UserGroupData } from "./data/user-group";
import { MockDataModule } from "./mock/mock-data.module";
import { throwIfAlreadyLoaded } from "./module-import-guard";
import { StoreModule } from "./store/store.module";
import { AccountPlanAnaliticaData, AccountPlanSinteticaData } from './data/account-plan';

//Datas





//Services

import { AccountPlanService } from './service/account-plan.service';
import { AccountTypeService } from "./service/account-type.service";
import { AccountService } from "./service/account.service";
import { AssetReductionService } from "./service/asset-reduction.service";
import { AuthService } from "./service/auth.service";
import { BankService } from "./service/bank.service";
import { ClientService } from "./service/client.service";
import { CommonService } from "./service/common.service";
import { ContextService } from "./service/context.service";
import { CostCenterService } from "./service/cost-center.service";
import { CreditSummaryService } from "./service/credit-summary.service";
import { DailyConsolidationService } from "./service/daily-consolidation.service";
import { DemonstrativeService } from "./service/demonstrative.service";
import { DocumentService } from "./service/document.service";
import { FuncionalityService } from "./service/funcionality.service";
import { JuridicAccountService } from "./service/juridic-account.service";
import { JuridicAddressService } from "./service/juridic-address.service";
import { JuridicContactService } from "./service/juridic-contact.service";
import { JuridicDocumentService } from "./service/juridic-document.service";
import { JuridicPartnerService } from "./service/juridic-partner.service";
import { JuridicRestrictionService } from "./service/juridic-restriction.service";
import { JuridicService } from "./service/juridic.service";
import { JuridicTypeService } from "./service/juridic_type.service";
import { LocalStorageService } from "./service/local-storage.service";
import { MenuService } from "./service/menu.service";
import { ModuleService } from "./service/module.service";
import { NatureCountService } from "./service/nature-count.service";
import { ParamService } from "./service/param.service";
import { PjService } from "./service/person.service";
import { ProfilesService } from "./service/profiles.service";
import { ProvisionCountService } from "./service/provision.service";
import { RestrictionService } from "./service/restriction.service";
import { TaxService } from "./service/tax.service";
import { TransactionService } from "./service/transaction.service";
import { UserAccountService } from "./service/user-account.service";
import { UserGroupService } from "./service/user-group.service";
import { UserService } from "./service/user.service";
//Services


const DATA_SERVICES = [
  { provide: LocalStorageData, useClass: LocalStorageService },
  { provide: UserData, useClass: UserService },
  { provide: AccountData, useClass: AccountService },
  { provide: BankData, useClass: BankService },
  { provide: ContextData, useClass: ContextService },
  { provide: UserAccountData, useClass: UserAccountService },
  { provide: TransactionData, useClass: TransactionService },
  { provide: DailyConsolidationData, useClass: DailyConsolidationService },
  { provide: CreditSummaryData, useClass: CreditSummaryService },
  { provide: ParamData, useClass: ParamService },
  { provide: CommonData, useClass: CommonService },
  { provide: UserGroupData, useClass: UserGroupService },
  { provide: ModuleData, useClass: ModuleService },
  { provide: FuncionalityData, useClass: FuncionalityService },
  { provide: MenuData, useClass: MenuService },
  { provide: AuthData, useClass: AuthService },
  { provide: ClientData, useClass: ClientService },
  { provide: DemonstrativeData, useClass: DemonstrativeService },
  { provide: CostCenterData, useClass: CostCenterService },
  { provide: NatureCountData, useClass: NatureCountService },
  { provide: ProvisionCountData, useClass: ProvisionCountService },
  { provide: AssetReductionData, useClass: AssetReductionService },
  { provide: TaxData, useClass: TaxService },
  { provide: AccountPlanAnaliticaData, useClass: AccountPlanService },
  { provide: AccountPlanSinteticaData, useClass: AccountPlanService },
  { provide: PersonData, useClass: PjService },
  { provide: PersonData, useClass: PjService },
  { provide: DocumentData, useClass: DocumentService },
  { provide: RestrictionData, useClass: RestrictionService },
  { provide: AccountTypeData, useClass: AccountTypeService },
  { provide: ProfilesData, useClass: ProfilesService },
  { provide: JuridicTypeData, useClass: JuridicTypeService },
  { provide: JuridicData, useClass: JuridicService },
  { provide: JuridicContactData, useClass: JuridicContactService },
  { provide: JuridicAddressData, useClass: JuridicAddressService },
  { provide: JuridicAccountData, useClass: JuridicAccountService },
  { provide: JuridicPartnerData, useClass: JuridicPartnerService },
  { provide: JuridicRestrictionData, useClass: JuridicRestrictionService },
  { provide: JuridicDocumentData, useClass: JuridicDocumentService },
];

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return observableOf("guest");
  }
}

export const NB_CORE_PROVIDERS = [
  AuthGuard,
  ...MockDataModule.forRoot().providers,
  ...DATA_SERVICES,
  ...NbAuthModule.forRoot({
    strategies: [
      NbPasswordAuthStrategy.setup({
        name: "email",

        token: {
          class: NbAuthJWTToken,
        },

        baseEndpoint: environment.services.accessControl.baseUrl,
        // baseEndpoint: ' http://127.0.0.1:5000',
        login: {
          endpoint: "/api/v1/authentication/login",
        },
        requestPass: {
          redirect: {
            success: "/auth/reset-password",
          },
        },
        resetPass: {
          redirect: {
            success: "/auth/login",
          },
        },
        errors: {
          key: "data.errors",
        },
      }),
    ],
    forms: {
      login: {
        strategy: "email",
        redirectDelay: 1000,
        showMessages: {
          success: true,
          error: true,
        },
      },
    },
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: "*",
      },
      user: {
        parent: "guest",
        create: "*",
        edit: "*",
        remove: "*",
      },
    },
  }).providers,

  {
    provide: NbRoleProvider,
    useClass: NbSimpleRoleProvider,
  },
];

@NgModule({
  imports: [CommonModule, StoreModule],
  exports: [NbAuthModule, AuthModule],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [...NB_CORE_PROVIDERS],
    };
  }
}
