import { Injectable } from '@angular/core';
import { FinancierFinancierRestrictionRequestDto, FinancierFinancierRestrictionResponseDto } from '../data/dto/financier.dto';
import { BaseResponse, BaseResponsePagination } from '../data/bases/base-response';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FinancierFinancierRestrictionService {

  private baseUrl = `${environment.services.project.baseUrl}/api/v1/financier-financier-restriction`;

  constructor(private http: HttpClient) { }

  insert(request: FinancierFinancierRestrictionRequestDto, financierId: string) {
    return this.http.post<FinancierFinancierRestrictionRequestDto>(`${this.baseUrl}/${financierId}`, {
      ...request,
      id: null
    })
  }

  getByFinancierId(id: string): Observable<BaseResponse<FinancierFinancierRestrictionResponseDto, BaseResponsePagination>> {
    return this.http.get<
      BaseResponse<FinancierFinancierRestrictionResponseDto, BaseResponsePagination>
    >(`${this.baseUrl}/by-financier/${id}`);
  }

  getByRestrictionId(id: string): Observable<BaseResponse<FinancierFinancierRestrictionResponseDto, BaseResponsePagination>> {
    return this.http.get<
      BaseResponse<FinancierFinancierRestrictionResponseDto, BaseResponsePagination>
    >(`${this.baseUrl}/${id}`);
  }

  delete(id: string) {
    return this.http.delete(`${this.baseUrl}/${id}`)
  }
}
