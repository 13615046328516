import { IEnvironment } from "./environment.interface";

export const environment: IEnvironment = {
  production: false,
  services: {
    financial: {
      baseUrl: "https://fin.dev.projetamais.com.br",
    },
    accessControl: {
      baseUrl: "https://acc.dev.projetamais.com.br",
    },
    project: {
      baseUrl: "https://prj.dev.projetamais.com.br",
    },
    accounting: {
      baseUrl: "https://act.dev.projetamais.com.br",
    },
    viaCep: {
      baseUrl: "https://viacep.com.br/ws",
    },
  },
  baseUrl: "",
  toastPosition: "top-right", //TOP_RIGHT="top-right",TOP_LEFT="top-left",BOTTOM_RIGHT="bottom-right",BOTTOM_LEFT="bottom-left"
  pages: {
    defaultForOptions: {
      recordsPerPage: 100,
    },
    selects: {
      recordsPerPage: 5,
    },
    user: {
      recordsPerPage: 10,
    },
    account: {
      recordsPerPage: 10,
    },
    userGroup: {
      recordsPerPage: 10,
    },
    credit: {
      recordsPerPage: 50,
    },
    banks: {
      recordsPerPage: 500,
    },
    permissions: {
      recordsPerPage: 10,
    },
    client: {
      recordsPerPage: 10,
    },
    demonstrative: {
      recordsPerPage: 10,
    },
    costCenter: {
      recordsPerPage: 10,
    },
    nature: {
      recordsPerPage: 10,
    },
    provision: {
      recordsPerPage: 10,
    },
    assetReduction: {
      recordsPerPage: 10,
    },
    tax: {
      recordsPerPage: 10,
    },
    logHistory: {
      recordsPerPage: 10,
    },
    contact: {
      recordsPerPage: 10,
    },
    document: {
      recordsPerPage: 10,
    },
    restriction: {
      recordsPerPage: 10,
    },
    agreement: {
      recordsPerPage: 10,
    },
    financier: {
      recordsPerPage: 10,
    },
  },
  financial: {
    banks: [
      {
        code: "001",
        settings: {
          transaction: {
            showStatusTransaction: true,
            allowSync: true,
            importOFX: false,
          },
        },
      },
      {
        code: "041",
        settings: {
          transaction: {
            showStatusTransaction: false,
            allowSync: false,
            importOFX: true,
          },
        },
      },
    ],
  },
};
