import { LocalDataSource } from "ng2-smart-table";
import { merge } from "rxjs";
import {
  catchError,
  finalize,
  map,
  startWith,
  switchMap,
} from "rxjs/operators";
import {
  BaseResponse,
  BaseResponsePagination,
} from "src/app/@core/data/bases/base-response";
import { JuridicItemListResponseDto, JuridicResponseDto } from "src/app/@core/data/dto/juridic.dto";
import { JuridicData } from "src/app/@core/data/juridic";
import { User } from "src/app/@core/data/user";
import { FormService } from "src/app/@core/service/form.service";

import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { NbDialogRef } from "@nebular/theme";

import { PJ_SELECT_FILTER_FORM } from "./pj-select.form";
import { PjSelectTableSettings } from "./pj-select.tablesettings";
import { JuridicType, JuridicTypeData } from "src/app/@core/data/juridic_type";
import { throwCustomError } from "src/app/@core/utils/error/error-utils";
import { environment } from "src/environments/environment";
import { ToastService } from "src/app/@core/utils/toast/toast.service";
import { PJSelectDisabledFields, PJSelectFilter } from "./pj-select.interfaces";

@Component({
  selector: "ngx-pj-select",
  templateUrl: "./pj-select.component.html",
  styleUrls: ["./pj-select.component.scss"],
})
export class PjSelectComponent implements OnInit {
  @Input() disabledFields: PJSelectDisabledFields = {
    comercialName: false,
    companyName: false,
    cnpj: false,
    juridicTypes: false,
  };
  @Input() filters: PJSelectFilter = {
    comercialName: "",
    companyName: "",
    cnpj: "",
    juridicTypesIn: [],
    juridicTypesAnd: [],
  };

  showFilter: boolean = false;
  source: JuridicItemListResponseDto[];
  settings = PjSelectTableSettings;
  pagination = {
    currentPage: 1,
    pageSize: this.settings.pager.perPage,
  };
  total: number = 0;
  isLoading: boolean = false;
  form: FormGroup;
  juridicTypeOptions: JuridicType[] = [];

  constructor(
    private buildForm: FormBuilder,
    public formService: FormService,
    private service: JuridicData,
    public juridicTypeService: JuridicTypeData,
    private toastService: ToastService,
    protected ref: NbDialogRef<PjSelectComponent>
  ) {
    this.form = this.buildForm.group(PJ_SELECT_FILTER_FORM);
  }

  async ngOnInit() {
    await this.loadJuridicType();
    this.loadForm();
    this.onList();
  }

  onList() {
    merge()
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoading = true;
          const filter = this.form.value;
          return this.service.list({ ...filter, ...this.pagination });
        }),
        map((data) => {
          this.isLoading = false;
          return data;
        }),
        catchError((err) => {
          return err;
        })
      )
      .subscribe((result: BaseResponse<JuridicResponseDto[], any>) => {
        console.log('responsepj', result.result)
        this.total = result.pagination?.count;
        this.source = result.result;
      });
  }
  public loadJuridicType() {
    return new Promise((resolve, reject) => {
      this.isLoading = true;
      this.juridicTypeService
        .list({ pageSize: environment.pages.defaultForOptions.recordsPerPage })
        .pipe(
          finalize(() => (this.isLoading = false)),
          catchError((error) => {
            this.toastService.errorMsg(
              throwCustomError(
                error,
                "Ocorreu um erro ao buscar tipos de pessoa jurídica."
              )
            );
            reject(error);
            return error;
          })
        )
        .subscribe(
          (response: BaseResponse<JuridicType[], BaseResponsePagination>) => {
            this.juridicTypeOptions = response.result;
            resolve(true);
          }
        );
    });
  }
  onClickRow(event) {
    this.ref.close(event.data);
  }

  onClear() {
    this.form.reset();
    this.onList();
  }

  onFilter() {
    this.onList();
  }

  onToggleFilter() {
    if (this.showFilter) this.showFilter = false;
    else this.showFilter = true;
  }

  close() {
    this.ref.close(false);
  }

  onCurrentPageChange(currentPage) {
    this.pagination = { ...this.pagination, currentPage };
    this.onList();
  }

  public onTypeToggle(id: string, checked: boolean) {
    let newValues = [...this.form.controls["juridicTypeIdsIn"].value];
    if (checked && !this.isTypeChecked(id)) {
      newValues = [...newValues, id];
    } else {
      const index = newValues.indexOf(id);
      newValues.splice(index, 1);
    }

    this.form.controls["juridicTypeIdsIn"].setValue(newValues);
    this.form.controls["juridicTypeIdsIn"].markAsTouched();
  }
  public isTypeChecked(id: string) {
    const values: string[] = this.form.controls["juridicTypeIdsIn"].value;

    return values?.includes(id);

  }
  public loadForm() {
    let juridicTypeIdsIn: string[] = [];
    let juridicTypeIdsAnd: string[] = [];
    if (this.filters.juridicTypesIn.length > 0) {
      juridicTypeIdsIn = this.juridicTypeOptions
        .filter((type) => this.filters.juridicTypesIn.includes(type.code))
        .map((type) => type.id);
    }
    if (this.filters.juridicTypesAnd.length > 0) {
      juridicTypeIdsAnd = this.juridicTypeOptions
        .filter((type) => this.filters.juridicTypesAnd.includes(type.code))
        .map((type) => type.id);
    }
    this.form.patchValue({
      ...this.filters,
      juridicTypeIdsIn,
      juridicTypeIdsAnd,
    });
  }
}
