import { Component, Input, OnInit } from "@angular/core";
import { NbDialogRef } from "@nebular/theme";
import { LogSummary } from "src/app/@core/data/dto/log.dto";

@Component({
  selector: "ngx-history-display",
  templateUrl: "./history-display.component.html",
  styleUrls: ["./history-display.component.scss"],
})
export class HistoryDisplayComponent implements OnInit {
  constructor(protected ref: NbDialogRef<HistoryDisplayComponent>) {}

  @Input() currentVersionData: any;
  @Input() previousVersionData: any;
  @Input() moduleName: string;
  @Input() currentVersionSummary: LogSummary;
  @Input() previousVersionSummary: LogSummary;

  currentVersionDataArray: any[] = [];
  previousVersionDataArray: any[] = [];

  ngOnInit(): void {
    this.currentVersionDataArray = this.transformObjectToArray(
      this.currentVersionData
    );
    this.previousVersionDataArray = this.transformObjectToArray(
      this.previousVersionData
    );
  }

  isArray(value: string | any[]): boolean {
    return Array.isArray(value);
  }

  private transformObjectToArray(
    obj: Record<string, any>
  ): { label: string; value: any }[] {
    return Object.entries(obj).map(([key, value]) => {
      if (Array.isArray(value)) {
        return {
          label: key,
          value: value.map((item) => this.transformObjectToArray(item)),
        };
      }
      return { label: key, value };
    });
  }
}
