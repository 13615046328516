import * as moment from "moment";

export function getMonthDifference(startDate, endDate) {
  return (
    endDate.getMonth() -
    startDate.getMonth() +
    12 * (endDate.getFullYear() - startDate.getFullYear())
  );
}
function monthInFullToNumber(month: string): number {
  switch (month) {
    case "Jan":
      return 0;
    case "Fev":
      return 1;
    case "Mar":
      return 2;
    case "Abr":
      return 3;
    case "Mai":
      return 4;
    case "Jun":
      return 5;
    case "Jul":
      return 6;
    case "Ago":
      return 7;
    case "Set":
      return 8;
    case "Out":
      return 9;
    case "Nov":
      return 10;
    case "Dez":
      return 11;
    default:
      return -1;
  }
}
export function dateInFull(data: Date) {
  const agora = new Date(Date.now());
  const tempo =
    Math.trunc(agora.getTime() / 1000) - Math.trunc(data.getTime() / 1000);
  const locale = "pt-BR";
  const format = "DD/MM/YY - HH:mm";

  return "em " + moment(data).locale(locale).format(format).toLowerCase();

  // if (tempo < 120)
  //   return "agora mesmo"

  // if (tempo < 3600) {
  //   let minuto = Math.trunc(tempo / 60)
  //   return "há " + minuto + (minuto > 1 ? " minutos" : " minuto")
  // }

  // if (tempo < 86400) {
  //   let hora = Math.trunc(tempo / 3600)
  //   return "há " + hora + (hora > 1 ? " horas" : " hora")
  // }

  // if (tempo < 172800) {
  //   let hr = data.getHours()
  //   return "ontem às " + hr + (hr > 1 ? "hrs" : "hr")
  // }

  // if (tempo < 604800)
  //   return moment(data).locale(locale).format('dddd').toLowerCase()

  // if (tempo < 1209600)
  //   return "semana passada"

  // if (agora.getMonth() - data.getMonth() == 1)
  //   return "mês passado"

  // if (tempo < 2419200) {
  //   let semana = Math.trunc(tempo / 86400 / 7)
  //   return "há " + semana + (semana > 1 ? " semanas" : " semana")
  // }

  // if (getMonthDifference(data, agora) < 12) {
  //   let mes = getMonthDifference(data, agora)
  //   return "há " + mes + (mes > 1 ? " meses" : " mês")
  // }

  // let ano = agora.getFullYear() - data.getFullYear()
  // return "há " + ano + (ano > 1 ? " anos" : " ano")
}
export function monthInFull(month: number): string {
  switch (month) {
    case 1:
      return "Jan";
    case 2:
      return "Fev";
    case 3:
      return "Mar";
    case 4:
      return "Abr";
    case 5:
      return "Mai";
    case 6:
      return "Jun";
    case 7:
      return "Jul";
    case 8:
      return "Ago";
    case 9:
      return "Set";
    case 10:
      return "Out";
    case 11:
      return "Nov";
    case 12:
      return "Dez";
    default:
      return "";
  }
}
export function monthInFullToDate(monthInFull: string): Date {
  const month: number = monthInFullToNumber(monthInFull.split("/")[0]);
  const year: number = parseInt("20" + monthInFull.split("/")[1]);
  return new Date(year, month, 1);
}
export function toBackendDateFormat(date: Date): string {
  if (!date) return "";
  return (
    date.getFullYear().toString() +
    "-" +
    String(date.getMonth() + 1).padStart(2, "0") +
    "-" +
    date.getDate().toString().padStart(2, "0")
  );
}

export function dateToFormat(
  date: Date,
  format = "DD/MM/YY",
  locale = "pt-BR"
): string {
  try {
    if (isNaN(date.getTime())) return "";
    return moment(date).locale(locale).format(format);
  } catch (error) {
    return "";
  }
}
export function dateToFormatFromString(
  dateString,
  format = "DD/MM/YY",
  locale = "pt-BR"
) {
  if (!dateString || dateString == "") return null;

  return dateToFormat(new Date(dateString), format, locale);
}

export function fromBackendDateFormat(date: string): Date {
  let year = parseInt(date.split("-")[0]);
  let month = parseInt(date.split("-")[1]) - 1;
  let day = parseInt(date.split("-")[2]);
  return new Date(year, month, day);
}
export function fromBrasilianDateFormat(date: string): Date {
  let day = parseInt(date.split("/")[0]);
  let month = parseInt(date.split("/")[1]) - 1;
  let year = parseInt(date.split("/")[2]);
  return new Date(year, month, day);
}

export function customDateParser(date, format) {
  switch (format) {
    case "dd/MM/yyyy":
      return fromBrasilianDateFormat(date);
      break;
    default:
      return new Date(Date.parse(date));
  }
}
