import { Observable } from "rxjs";

import { BaseFilter } from "./bases/base-filter";
import { BaseResponse, BaseResponsePagination } from "./bases/base-response";
import {
  ParticipantRequestDto,
  ParticipantResponseDto,
} from "./dto/participant.dto";
import { ParticipantTypeDeleteEnum } from "../utils/enum/participant-type-delete.enum";

export interface ParticipantFilter extends BaseFilter {
  agreementId?: string;
}

export abstract class ParticipantData {
  abstract list(
    filter: ParticipantFilter
  ): Observable<BaseResponse<any[], BaseResponsePagination>>;
  abstract getById(
    id: string
  ): Observable<BaseResponse<ParticipantResponseDto, BaseResponsePagination>>;
  abstract update(id: string, request: ParticipantRequestDto);
  abstract delete(id: string, type: ParticipantTypeDeleteEnum);
  abstract insert(request: ParticipantRequestDto);
}
