import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseResponse, BaseResponsePagination } from '../data/bases/base-response';
import { toBackendDateFormat } from '../utils/common/datetime-utils';
import { Tax, TaxData, TaxFilter } from '../data/tax';


@Injectable()
export class TaxService extends TaxData {

  private baseUrl = environment.services.accounting.baseUrl;

  constructor(private http: HttpClient) { super() }

  list(filter: TaxFilter, pagination: any): Observable<BaseResponse<Tax[], BaseResponsePagination>> {
   

    let params = new HttpParams()
      .set('page', pagination.currentPage)
      .set('page_size', pagination.pageSize);

     // Adiciona as datas apenas se elas estiverem definidas
     if (filter.start_date instanceof Date) {
      params = params.set('start_date', toBackendDateFormat(filter.start_date));
    }
    if (filter.end_date instanceof Date) {
      params = params.set('end_date', toBackendDateFormat(filter.end_date));
    }

    if (filter.acronym) params = params.set('acronym', filter.acronym);
    if (filter.description) params = params.set('description', filter.description);
    if (filter.tax_type) params = params.set('tax_type', filter.tax_type);

    params = params.set('orderBy', '').set('sortedBy', '');

    return this.http.get<BaseResponse<Tax[], BaseResponsePagination>>(
      `${this.baseUrl}/api/tax/`, 
      { params }
    );
  }


  delete(id){
    return this.http.delete(`${this.baseUrl}/api/tax/${id}`,id)
  }

  update(tax:Tax){
    const { id, ...taxData } = tax;
    return this.http.put(`${this.baseUrl}/api/tax/${tax.id}/`, taxData)
  }

  insert(tax:Tax){
    return this.http.post(`${this.baseUrl}/api/tax/`,tax)
  }

  get(id) {
    var result = this.http.get(`${this.baseUrl}/api/tax/${id}/`,id)
    return result;
  }

  getTax() {
    var result = this.http.get(`${this.baseUrl}/api/tax/`)
    return result;
  }


}
