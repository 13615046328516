import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import {
  BaseResponse,
  BaseResponsePagination,
} from "../data/bases/base-response";
import {
  DocumentRequestDto,
  DocumentResponseDto,
} from "../data/dto/document.dto";
import {
  JuridicDocumentData,
  JuridicDocumentFilter,
} from "../data/juridic-document";
import { setBaseFilter } from "../utils/http/base-http-filter";

@Injectable()
export class JuridicDocumentService extends JuridicDocumentData {
  private baseUrl = `${environment.services.accessControl.baseUrl}/api/v1/juridic_document`;

  constructor(private http: HttpClient) {
    super();
  }

  getById(
    id: string
  ): Observable<BaseResponse<DocumentResponseDto, BaseResponsePagination>> {
    return this.http.get<
      BaseResponse<DocumentResponseDto, BaseResponsePagination>
    >(`${this.baseUrl}/${id}`);
  }

  list(
    juridicId: string,
    filter: JuridicDocumentFilter
  ): Observable<BaseResponse<DocumentResponseDto[], BaseResponsePagination>> {
    var params = setBaseFilter(filter);
    if (filter.id) params = params.set("id", filter.id);

    return this.http.get<
      BaseResponse<DocumentResponseDto[], BaseResponsePagination>
    >(`${this.baseUrl}/by-juridic/${juridicId}`, { params });
  }

  insert(juridicId: string, formData: FormData): Observable<any> {
    return this.http.post(`${this.baseUrl}/${juridicId}`, formData);
  }
  delete(id: string): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }
}
