import { Injectable } from "@angular/core";
import { FormGroup, ValidationErrors } from "@angular/forms";

interface FormError {
  key: string;
  keyError: string;
  error: any;
}
@Injectable({
  providedIn: "root",
})
export class FormService {
  public markAllTouched(form: FormGroup): void {
    Object.values(form.controls).forEach((control) => {
      if (control instanceof FormGroup) {
        this.markAllTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  public setErrorsNull(form: FormGroup): void {
    Object.values(form.controls).forEach((control) => {
      if (control instanceof FormGroup) {
        this.setErrorsNull(control);
      } else {
        control.setErrors(null);
      }
    });
  }

  public updateValueAndValidity(form: FormGroup): void {
    Object.values(form.controls).forEach((control) => {
      if (control instanceof FormGroup) {
        this.updateValueAndValidity(control);
      } else {
        control.updateValueAndValidity();
      }
    });
  }

  public hasError(control: string, form: any): boolean {
    const result = Object.keys(this.getErrors(control, form));
    return result.length > 0;
  }

  public getErrors(control: string, form: any): { [key: string]: any } {
    const formControl = form.controls[control];
    const erros = !!formControl?.errors ? formControl?.errors : null;
    if (!erros || !formControl.touched) {
      return {};
    }

    const errorKeys = Object.keys(erros);
    const result: any = {};
    errorKeys.forEach((key) => {
      result[key] = erros[key];
    });

    return result;
  }

  public getErrosStringList(control: string, form: any): string[] {
    const formErros = this.getErrors(control, form);
    const errors = Object.keys(formErros);
    return errors.map((error) =>
      this.getErrorMessage(error, formErros[error] || {})
    );
  }

  getErrorMessage(errorKey: string, options: any = {}): string {
    const errorMessages: { [key: string]: string } = {
      required: "Campo obrigatório.",
      email: "Email inválido.",
      mask: `Insira um valor no formato ${options.requiredMask}.`,
      minArrayLength: `Selecione pelo menos ${
        options.requiredLength || "uma"
      } opção.`,
      lessThan: `${options.to} deve ser maior que ${options.from}.`,
      min: `Valor deve ser maior ou igual a ${options.min}.`,
      isNotSupported: "O participante selecionado não é uma apoiada.",
    };
    if (!errorMessages[errorKey]) console.log(errorMessages, errorKey);
    return errorMessages[errorKey] || "Erro desconhecido";
  }

  getErrorMessages(formErros: FormError[]): string[] {
    const errors: string[] = [];
    formErros.forEach((formError) => {
      errors.push(this.getErrorMessage(formError.keyError));
    });
    return errors;
  }

  public getFormValidationErrors(form: any): FormError[] {
    const errors: FormError[] = [];
    Object.keys(form.controls).forEach((key) => {
      const controlErrors: ValidationErrors | undefined | null =
        form.get(key)?.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          errors.push({ key, keyError, error: controlErrors[keyError] });
        });
      }
    });
    return errors;
  }
}
