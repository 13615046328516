import { Component } from '@angular/core';
import { Version } from '../../../@core/data/common';
import { LocalStorageData } from '../../../@core/data/local-storage';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      Created with ♥ by
      <a href="https://deeppixel.com.br/" target="_blank">
      <img src="assets/images/deeppixel-logo.png" class="logo-image">
    </a>
    </span>
    <div class="created-by">
      v{{version?.name}}
    </div>
    <!-- <div class="socials"> -->

      <!-- <a href="#" target="_blank" class="ion ion-social-github"></a>
      <a href="#" target="_blank" class="ion ion-social-facebook"></a>
      <a href="#" target="_blank" class="ion ion-social-linkedin"></a> -->
    <!-- </div> -->
  `,
})
export class FooterComponent {
  version:Version

  constructor(
    private localStorage:LocalStorageData
  ){
    this.version = this.localStorage.get('version')
  }
}
