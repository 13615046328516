import { Ng2SmartTableModule } from 'ng2-smart-table';

import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbSecurityModule } from '@nebular/security';
import {
    NbActionsModule, NbButtonModule, NbCardModule, NbCheckboxModule, NbContextMenuModule,
    NbIconModule, NbInputModule, NbLayoutModule, NbMenuModule, NbSearchModule, NbSelectModule,
    NbSidebarModule, NbThemeModule, NbUserModule
} from '@nebular/theme';

import {
    BankInformationsSelectComponent, ColumnSelectorSelectComponent, DialogComponent,
    EmailSelectComponent, FooterComponent, HeaderComponent, SearchInputComponent, TinyMCEComponent,
    UploadFileComponent
} from './components';
import {
    ActionsDropdownComponent
} from './components/access/actions-dropdown/actions-dropdowns.component';
import { AccessLicenseComponent } from './components/access/license/access-license.component';
import {
    ActionCheckboxComponent
} from './components/dialogs/column-selector-select/action-checkbox/action-checkbox.component';
import {
    ContactSelectComponent
} from './components/dialogs/contact-select/contact-select.component';
import { ModalTaxComponent } from './components/dialogs/modal-tax/modal-tax.component';
import { InputComponent } from './components/input/input.component';
import { PagerComponent } from './components/pager/pager.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { UploaderComponent } from './components/uploader/uploader.component';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import {
    OneColumnLayoutComponent, ThreeColumnsLayoutComponent, TwoColumnsLayoutComponent
} from './layouts';
import { CapitalizePipe, NumberWithCommasPipe, PluralPipe, RoundPipe, TimingPipe } from './pipes';
import { CORPORATE_THEME } from './styles/theme.corporate';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { DARK_THEME } from './styles/theme.dark';
import { DEFAULT_THEME } from './styles/theme.default';
import {
  NbSpinnerModule,
} from "@nebular/theme";
import { PjSelectComponent } from "./components/dialogs/pj-select/pj-select.component";
import { ObservationsSelectComponent } from './components/dialogs/observations-select/observations-select.component';
import { VersionHistoryComponent } from './components/dialogs/version-history/version-history.component';
import { HistoryDisplayComponent } from './components/dialogs/version-history/history-display/history-display.component';
import { VersionHistoryActionComponent } from './components/dialogs/version-history/version-history-action/version-history-action.component';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbCardModule,
  FormsModule,
  NbInputModule,
  NbSelectModule,
  Ng2SmartTableModule,
  NbCheckboxModule,
  FormsModule,
  ReactiveFormsModule,
  NbSpinnerModule,
];
const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  TinyMCEComponent,
  DialogComponent,
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  PagerComponent,
  SpinnerComponent,
  UploaderComponent,
  EmailSelectComponent,
  BankInformationsSelectComponent,
  ActionsDropdownComponent,
  AccessLicenseComponent,
  ModalTaxComponent,
  ContactSelectComponent,
  ColumnSelectorSelectComponent,
  ActionCheckboxComponent,
  InputComponent,
  PjSelectComponent,
  UploadFileComponent,
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
];
const DIRECTIVES = [DragAndDropDirective];

@NgModule({
  imports: [CommonModule, ...NB_MODULES],
  exports: [CommonModule, ...PIPES, ...COMPONENTS, ...DIRECTIVES],
  declarations: [
    ...COMPONENTS,
    ...PIPES,
    ...DIRECTIVES,
    SpinnerComponent,
    ContactSelectComponent,
    ObservationsSelectComponent,
    VersionHistoryComponent,
    HistoryDisplayComponent,
    VersionHistoryActionComponent
  ],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: "default",
          },
          [DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME]
        ).providers,
      ],
    };
  }
}
