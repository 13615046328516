import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { AgreementData, AgreementFilter } from "../data/agreement";
import {
  BaseResponse,
  BaseResponsePagination,
} from "../data/bases/base-response";
import {
  AgreementItemListResponseDto,
  AgreementRequestDto,
  AgreementResponseDto,
} from "../data/dto/agreement.dto";
import { setBaseFilter } from "../utils/http/base-http-filter";

@Injectable()
export class AgreementService extends AgreementData {
  private baseUrl = `${environment.services.project.baseUrl}/api/v1/agreement`;
  // private baseUrl = `${environment.services.project.baseUrl}/api/v1/agreement`;
  

  constructor(private http: HttpClient) {
    super();
  }

  list(
    filter: AgreementFilter
  ): Observable<
    BaseResponse<AgreementItemListResponseDto[], BaseResponsePagination>
  > {
    var params = setBaseFilter(filter);
    if (filter.agreementIdentifier)
      params = params.set("agreementIdentifier", filter.agreementIdentifier);
    if (filter.projectNumber)
      params = params.set("projectNumber", filter.projectNumber);
    if (filter.projectName)
      params = params.set("projectName", filter.projectName);
    if (filter.supported) params = params.set("supported", filter.supported);
    if (filter.version) params = params.set("version", filter.version);
    if (filter.status) params = params.set("status", filter.status);
    if (filter.startDate) params = params.set("startDate", filter.startDate);
    if (filter.endDate) params = params.set("endDate", filter.endDate);
    if (filter.agreementTypeId)
      params = params.set("agreementTypeId", filter.agreementTypeId);
    if (filter.amount) params = params.set("amount", filter.amount);

    return this.http.get<
      BaseResponse<AgreementItemListResponseDto[], BaseResponsePagination>
    >(`${this.baseUrl}`, { params });
  }
  getById(
    id: string
  ): Observable<BaseResponse<AgreementResponseDto, BaseResponsePagination>> {
    return this.http.get<
      BaseResponse<AgreementResponseDto, BaseResponsePagination>
    >(`${this.baseUrl}/${id}`);
  }
  update(id: string, request: AgreementRequestDto) {
    return this.http.put(`${this.baseUrl}/${id}/`, request);
  }
  delete(id: any) {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }
  insert(request: AgreementRequestDto) {
    return this.http.post<BaseResponse<string, BaseResponsePagination>>(
      `${this.baseUrl}`,
      {
        ...request,
        id: null,
      }
    );
  }
}
